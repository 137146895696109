<template>
  <div class="wrapper">
    <div class="searchbox">
      <el-form :inline="true">
        <el-form-item>
          <el-button type="primary" @click="refreshFn"><i class="el-icon-refresh"></i> 刷新</el-button>
          <el-button v-if="listType === ''" :disabled="activityFlag === '1'" type="primary" @click="addActivityItem('add')">新增活动项目</el-button>
          <span v-if="activityFlag === '1'" style="font-size: 12px; margin-left: 10px; color: #f00;">如需操作，请先下架活动！</span>
        </el-form-item>
      </el-form>
    </div>
    


    <el-table :data="tableData" stripe>
      <el-table-column prop="title" label="项目名称"></el-table-column>
      <el-table-column prop="content" label="项目描述"></el-table-column>
      <el-table-column prop="names" label="所属区域"></el-table-column>
      <!-- <el-table-column label="是否实名">
        <template slot-scope="scope">
          <span v-if="scope.row.joinflag == 0">实名</span>
          <span v-if="scope.row.joinflag == 1">不实名</span>
        </template>
      </el-table-column> -->
      <el-table-column label="时间参与方式">
        <template slot-scope="scope">
          <span v-if="scope.row.byday == 0">按照时间段参与</span>
          <span v-if="scope.row.byday == 1">按照天参与</span>
        </template>
      </el-table-column>
      <el-table-column prop="createtime" label="创建时间" width="150"></el-table-column>

      <el-table-column label="操作" fixed="right" width="300" align="center" v-if="listType === 'check'">
        <template slot-scope="scope">
          <el-button @click="itemDetail(scope.row)" type="primary">详情</el-button>
          <el-button @click="openInfosListFn(scope.row)" type="primary">项目信息</el-button>
        </template>
      </el-table-column>

      <el-table-column label="操作" fixed="right" width="350" align="center" v-else>
        <template slot-scope="scope">
          <el-button @click="itemDetailFn(scope.row)" type="primary">修改</el-button>
          <el-button @click="deleteActivityItemFn(scope.row)" type="danger"  :disabled="activityFlag === '1'">删除</el-button>
          <el-button @click="openInfosListFn(scope.row)" type="primary">项目信息</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="paginationbox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="fenye.pagesizes"
        :page-size="fenye.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="fenye.totalnum"
      >
      </el-pagination>
    </div>

    <!-- 新增或修改项目 -->
    <el-dialog append-to-body :title="editType=='add'?'新增项目':'编辑项目'" :visible.sync="dialogVisible"  width="70%" :before-close="handleClose" :close-on-click-modal='false'>
      <new-item :editType="editType" :activityFlag="activityFlag" :editItemid="editItemid" :actuuid="actuuid" @refreshItemsList="refreshItemsList" ref="item"></new-item>
    </el-dialog>

    <!-- 查看项目详情 -->
    <el-dialog append-to-body title="项目详情" :visible.sync="itemDetailDialogVisible"  width="70%"  :close-on-click-modal='false'>
      <new-item :editType="editType" :editItemid="editItemid" :actuuid="actuuid" ref="itemDetail"></new-item>
    </el-dialog>

    <!-- 项目信息列表 -->
    <el-dialog append-to-body title="项目信息列表"  :close-on-click-modal='false'  :visible.sync="infosDialogVisible"  width="70%">
      <info-list :itemid="infoListByItemId" :activityFlag="activityFlag" :listType="listType=='check'?listType:''" ref="itemInfoList"></info-list>
    </el-dialog>

  </div>
</template>

<script>
import {
  activityItemQuery,
  activityItemDel,
  itemDetail,
} from "@/api/acitvity/index.js";
import infoList from '../infos'
import newItem from './newItem.vue'
export default {
  name: "",
  components: {
    newItem,
    infoList
  },
  props: {
    actuuid:{
      type: String,
      default: ''
    },
    listType: {
      type: String,
      default: ''
    },
    activityFlag:{
      type: String,
      default: ''
    }
  },
  data() {
    return {
      tableData: [],
      currentPage: 1,
      fenye: {
        totalnum: 0,
        pagesizes: [10, 20, 30, 40, 50],
        pagesize: 10
      },
      dialogVisible: false,
      editType: 'add',
      itemid: null,
      //项目信息弹框
      infosDialogVisible: false,
      infoListByItemId: null,
      editItemid: null,
      //项目详情查看
      itemDetailDialogVisible: false,

    };
  },
  watch: {
    actuuid(newval,oldval){
      if(newval){
        this.currentPage = 1;
        this.getList();
      }
    }
  },
  created() {},
  mounted() {
    this.currentPage = 1;
    this.getList();
  },
  computed: {},
  methods: {
    handleClose(done) {
      this.$refs.item.resetForm('ruleForm');
      this.$refs.item.fileList = [];
      this.$refs.item.ruleForm.doingmsg = '';
      done();
    },
    //获取列表
    getList() {
      activityItemQuery({
          userid: this.GLOBAL.adminId(),
          pageSize: this.fenye.pagesize,
          pageNum: this.currentPage,
          actuuid: this.actuuid,
      }).then(res=>{
        if(res.result == 200){
          this.fenye.totalnum = res.detail.totalRow;
          this.tableData = res.detail.list;
        } else {
          this.$message.error(res.description);
        }
      })
    },
    //切换页面条数
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.fenye.pagesize = val;
      this.currentPage = 1;
      this.getList();
    },
    //切换分页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    //删除项目
    deleteActivityItemFn(row){
      // console.log(row)
      this.$confirm('是否确认删除该项目?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        cancelButtonClass: "btn-custom-cancel",
        type: 'warning'
      }).then(() => {
        activityItemDel({
            userid: this.GLOBAL.adminId(),
            itemid: row.itemid,
            actuuid: row.actuuid
        }).then(res=>{
          if(res.result == 200){
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.currentPage = 1;
            this.getList();
          } else {
            this.$message.error(res.description);
          }
        })
      }).catch(()=>{
        
        })
    },
    //编辑项目
    itemDetailFn(row){
      this.editType = 'update';
      this.editItemid = row.itemid;
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.item.getItemDetail();
      })
    },
    //查看项目详情
    itemDetail(row){
      this.editType = 'detail';
      this.editItemid = row.itemid;
      this.itemDetailDialogVisible = true;
      this.$nextTick(() => {
        this.$refs.itemDetail.getItemDetail();
      })
    },
    //刷新数据
    refreshFn(){
      this.currentPage = 1;
      this.getList();
    },
    //刷新列表并关闭弹框
    refreshItemsList(){
      this.refreshFn();
      this.dialogVisible = false;
    },
    //添加活动项目
    addActivityItem(type){
      this.editType = type;
      this.dialogVisible = true;
      this.$nextTick(()=>{
        this.$refs.item.isAdd();
      })
    },
    //打开活动项目信息
    openInfosListFn(row){
      // console.log(this.actuuid)
      console.log('row',row)
      this.infoListByItemId = row.itemid;
      this.infosDialogVisible = true;
      this.$nextTick(()=>{
        this.$refs.itemInfoList.currentPage = 1;
        this.$refs.itemInfoList.getList();
        this.$refs.itemInfoList.curItemDetail(this.actuuid);
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.searchbox{
  margin: 0 10px;
}
.paginationbox {
  text-align: right;
  margin-top: 20px;
}
</style>