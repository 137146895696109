//账号管理中的账号类型
let userType = [{
  label: '管理员',
  value: 0
}, {
  label: '社区',
  value: 1
}, {
  label: '第三方平台',
  value: 2
}];

//活动类型
let activityType = [{
  label: '普通活动',
  value: '0'
},{
  label: '场地预约',
  value: '1'
},{
  label: '公告活动',
  value: '2'
}]


//活动审核状态
let checkStatus = [{
  label: '未提交审核',  //可以提交审核
  value: 0
},{
  label: '待审核',
  value: 1
},{
  label: '审核通过',
  value: 2
},{
  label: '审核不通过', //可以提交审核
  value: 3
}]

//活动上下架状态
let upDownStatus = [{
  label: '上架',
  value: 1
},{
  label: '下架',
  value: 2
}]

//任务上下架状态
let taskUpDownStatus = [{
  label: '上架',
  value: '1'
},{
  label: '下架',
  value: '0'
}]


export default {
  userType,
  activityType,
  checkStatus,
  upDownStatus,
  taskUpDownStatus
}