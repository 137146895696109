<template>
  <el-dialog
    title="绘制站点"
    append-to-body
    @closed="initThisPage"
    :visible.sync="dialogMapVisible"
    width="1200px"
    height="auto"
  >
    <div class="container">
      <div class="xm">
        <el-radio-group v-model="radio">
          <el-radio :label="1">手选地图</el-radio>
          <el-radio :label="2">经纬度定位</el-radio>
          <el-radio :label="3">GPS定位</el-radio>
        </el-radio-group>
      </div>
      <div class="search-box" v-if="radio == 1">
        <input v-model="searchKey" type="search" id="search" />
        <button @click="searchByHand">搜索</button>
        <button @click="upGrideXy">提交</button>
        <div class="tip-box" id="searchTip"></div>
      </div>
      <div class="search-box" v-if="radio == 2" style="">
        <el-form ref="form" :model="formlist" label-width="60px">
          <el-col :span="8">
            <el-form-item label="经度">
              <el-input v-model="formlist.lng" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="纬度">
              <el-input v-model="formlist.lat" ></el-input>
            </el-form-item>
          </el-col>
        </el-form>

        <button @click="searchfadio">搜索</button>
        <button @click="upGrideXy">提交</button>
   
      </div>
      <div class="search-box" v-if="radio == 3">
         <el-form ref="form" :model="formlist" label-width="50px">
          <el-col :span="8">
            <el-form-item label="东经">
              <el-input v-model="formarr.ln1"  style="width:27%"></el-input> 度
			  <el-input v-model="formarr.ln2"  style="width:27%"></el-input> 分
			  <el-input v-model="formarr.ln3"  style="width:27%"></el-input> 秒
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="北纬">
              <el-input v-model="formarr.la1"  style="width:27%"></el-input> 度
			  <el-input v-model="formarr.la2"  style="width:27%"></el-input> 分
			  <el-input v-model="formarr.la3"  style="width:27%"></el-input> 秒
            </el-form-item>
          </el-col>
		   
        </el-form>

        <button @click="searchfadio1">搜索</button>
        <button @click="upGrideXy">提交</button>
   
     
      </div>
      <!--
			  amap-manager： 地图管理对象
			  vid：地图容器节点的ID
			  zooms： 地图显示的缩放级别范围，在PC上，默认范围[3,18]，取值范围[3-18]；在移动设备上，默认范围[3-19]，取值范围[3-19]
			  center： 地图中心点坐标值
			  plugin：地图使用的插件
			  events： 事件
			-->
      <div style="width:100%;margin-top:30px">
        <el-amap
          class="amap-box"
		  style="width:100%"
          :amap-manager="amapManager"
          :vid="'event-amap-vue'"
          :zoom="zoom"
          :center="center"
          :events="events"
        >
          <!-- 事件位置标记 -->
          <el-amap-marker
            v-for="(marker, index) in markers"
            :position="marker"
            :key="'marker-'+index"
          ></el-amap-marker>

          <!-- 网络中心点标记 -->
          <el-amap-marker
            v-for="(marker, index) in netMarkers"
            :position="marker.position"
            :vid="index"
            :key="'marker-'+index"
            :content="marker.content"
          ></el-amap-marker>

          <!-- 网格 -->
          <el-amap-polygon
            v-for="(polygon, index) in polygons"
            :vid="index"
            :key="index"
            :ref="`polygon_${index}`"
            :path="polygon.path"
            fillColor="yellow"
            fillOpacity="0.4"
            strokeWeight="3"
            strokeColor="#ff0000"
            strokeOpacity="0.4"
          ></el-amap-polygon>
        </el-amap>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { AMapManager, lazyAMapApiLoaderInstance } from "vue-amap";
import {ToDigital} from '@/utils/sgnbs'
let amapManager = new AMapManager();

export default {
  data() {
    let self = this;
    return {
		formarr:{
			ln1:null,
				ln2:null,
					ln3:null,
					la1:null,
					la2:null,
					la3:null
		},
      formlist: {
        lng: null,
        lat: null,
      },
      radio: 1,
      netShowFlag: true,
      netMarkers: [],
      dialogMapVisible: false,
      positionsXy: [],
      address: null,
      searchKey: "",
      amapManager,
      markers: [],
      polygons: [
        {
          path: [],
        },
      ],
      searchOption: {
        city: "南京",
        citylimit: true,
      },
      center: [118.734057, 32.150852], //沿江街道中心点
      zoom: 15,
      lng: 0,
      lat: 0,
      loaded: false,
      events: {
        init(map) {
          lazyAMapApiLoaderInstance.load().then(() => {
            self.initSearch();
          });
        },
        // 点击获取地址的数据
        click(e) {
          //console.log(e)
          self.markers = [];
          let { lng, lat } = e.lnglat;
          self.lng = lng;
          self.lat = lat;
          self.center = [lng, lat];
          self.markers.push([lng, lat]);
          // 这里通过高德 SDK 完成。
          let geocoder = new AMap.Geocoder({
            radius: 1000,
            extensions: "all",
          });
          geocoder.getAddress([lng, lat], function(status, result) {
            if (status === "complete" && result.info === "OK") {
              console.log(result);
              if (result && result.regeocode) {
                //console.log(result.regeocode.formattedAddress);
                self.address = result.regeocode.formattedAddress;
                self.searchKey = result.regeocode.formattedAddress;
                self.$nextTick();
              }
            }
          });
        },
      },
    };
  },
  watch: {
    // dialogMapVisible(newval, oldval) {
    // 	if (newval == true) {
    // 		this.getGridlistXy();
    // 	}
    // },
    netShowFlag(newval, oldval) {
      //显示网格
      //显示网格中心点
      if (newval == true) {
        this.polygons = [
          {
            path: [],
          },
        ];
        this.netMarkers = [];
        // this.getGridlistXy();
      } else {
        this.polygons = [
          {
            path: [],
          },
        ];
        this.netMarkers = [];
      }
    },
    searchKey(newval, oldval) {
      if (newval.trim() == "") {
        this.lng = 0;
        this.lat = 0;
        this.address = "";
      }
    },
  },
  created() {},
  mounted() {},
  methods: {
	  searchfadio1(){
this.formlist.lng=null
	this.formlist.lat=null
	let a=ToDigital(this.formarr.ln1,this.formarr.ln2,this.formarr.ln3)
	let b=ToDigital(this.formarr.la1,this.formarr.la2,this.formarr.la3)
	this.formlist.lng=a
	this.formlist.lat=b
					console.log(1245,this.formarr.ln1,this.formarr.ln2,this.formarr.ln3,a)
					    this.searchfadio()
	  },
    searchfadio() {
      const that = this;
      let geocoder = new AMap.Geocoder({
        radius: 1000,
        extensions: "all",
	  });
	  if(this.formlist.lng&& this.formlist.lat){

	
      console.log(this.formlist);
      geocoder.getAddress([this.formlist.lng, this.formlist.lat], function(
        status,
        result
      ) {
        that.markers = [];
        that.center = [];
        if (status === "complete" && result.info === "OK") {
          if (result && result.regeocode) {
            that.center = [that.formlist.lng, that.formlist.lat];
            that.markers.push([that.formlist.lng, that.formlist.lat]);
            that.lng = that.formlist.lng;
            that.lat = that.formlist.lat;
               that.address = result.regeocode.formattedAddress;
                that.searchKey = result.regeocode.formattedAddress;
            // that.searchKey = address;
          }
		}
		
	  });
	    }
    },
    //关闭页面初始化变量
    initThisPage() {
      this.dialogMapVisible = false;
      this.netShowFlag = true;
    },
    //获取部门列表
    getGridlistXy() {
      var that = this;
      that
        .$axios({
          method: "post",
          url: "/wangge/gridlistXy",
          params: {
            "x-access-token": that.GLOBAL.token,
            userid: that.GLOBAL.adminId,
          },
        })
        .then((res) => {
          if (res.data.result == 200) {
            //console.log(res.data);
            that.positionsXy = res.data.detail;
            //var fillcol = [];
            var markers = []; //网格名称的点标注
            //var shequcolor = ["#8152B0","#005E9C","#EF4E7D","#00B48D","#01AEF2","#7CFC00","#698B22","#4169E1","#00F5FF","#FFA52A","#78C6ED","#D42C94","#F2583E"];

            for (var i in that.positionsXy) {
              let gridcenterxy = that.positionsXy[i].gridcenterxy.split(",");
              markers.push({
                position: [gridcenterxy[0], gridcenterxy[1]],
                content: `<div style="box-shadow:2px 2px 2px rgba(0,0,0,0.2); background:rgba(255,255,255,0.8); border-radius: 4px; min-width:120px; text-align:center;  padding:4px 10px; color:#000; font-weight:bold;">${that.positionsXy[i].grid_name}</div>`,
              });
              var array1 = that.positionsXy[i].gridxy.split(";");
              var polygonArr = new Array(); //多边形覆盖物节点坐标数组
              for (var j in array1) {
                var xy = array1[j];
                var ids = xy.split(",");
                var json = [];
                json.push(ids[0]);
                json.push(ids[1]);
                polygonArr.push(json);
              }
              /* 
									//填充颜色
									var shequindex = Math.floor((Math.random()*shequcolor.length));
									fillcol = shequcolor[shequindex];
									var polygon = new AMap.Polygon({
											path: polygonArr,//设置多边形边界路径
											strokeColor: '#FF0000',
											strokeOpacity: 0.2, //线透明度
											strokeWeight: 3,    //线宽
											fillColor: fillcol, //填充色
											fillOpacity: 0.5,//填充透明度
									});
									polygon.setMap(amapManager.getMap());
									 */

              let paths = that.polygons[0].path;
              paths.push(polygonArr);
            }
            this.netMarkers = markers;
            //console.log(that.polygons[0])
          } else {
            that.$message({
              message: res.data.description,
              type: "warning",
            });
          }
        });
    },
    initSearch() {
      let vm = this;
      let map = this.amapManager.getMap();
      AMapUI.loadUI(["misc/PoiPicker"], function(PoiPicker) {
        var poiPicker = new PoiPicker({
          input: "search",
          placeSearchOptions: {
            map: map,
            pageSize: 10,
          },
          suggestContainer: "searchTip",
          searchResultsContainer: "searchTip",
        });
        vm.poiPicker = poiPicker;
        // 监听poi选中信息
        poiPicker.on("poiPicked", function(poiResult) {
          //console.log(poiResult)
          let source = poiResult.source;
          let poi = poiResult.item;
          if (source !== "search") {
            poiPicker.searchByKeyword(poi.name);
          } else {
            poiPicker.clearSearchResults();
            vm.markers = [];
            let lng = poi.location.lng;
            let lat = poi.location.lat;
            let address = poi.cityname + poi.adname + poi.name;
            vm.center = [lng, lat];
            vm.markers.push([lng, lat]);
            vm.lng = lng;
            vm.lat = lat;
            vm.address = address;
            vm.searchKey = address;
          }
        });
      });
    },
    searchByHand() {
      if (this.searchKey !== "") {
        console.log(this.searchKey);
        this.poiPicker.searchByKeyword(this.searchKey);
      }
    },
    upGrideXy() {
      if (this.lng == 0 && this.lat == 0 && this.address == "") {
        this.$message({
          message: "事件位置不能为空",
          type: "warning",
        });
        return false;
      }
      if (this.lng == 0 || this.lat == 0 || this.address == "") {
        this.$message({
          message: "操作有误，请重新选择坐标",
          type: "warning",
        });
        return false;
      }
      let mapData = [this.lng, this.lat, this.address];
      //console.log(mapData)
      this.$emit("getEventMapData", mapData);
      this.dialogMapVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">

.container {
  position: relative;
  width: 100%;
  height: 550px;
}

.container .btns {
  position: absolute;
  top: 0;
  right: 0;
}

.search-box {
  position: relative;
  z-index: 5;
  width: 100%;
  height: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.search-box input {
  float: left;
  width: 70%;
  height: 100%;
  border: 1px solid #409eff;
  padding: 0 8px;
  outline: none;
}

.search-box button {
  float: left;
  width: 14%;
  height: 100%;
  background: #409eff;
  border: 1px solid #409eff;
  color: #fff;
  outline: none;
  margin-left: 1%;
}

.amap-box {
  width: 100% !important;
  height: 460px;
}
.tip-box {
  width: 100%;
  max-height: 260px;
  position: absolute;
  top: 30px;
  overflow-y: auto;
  background-color: #fff;
}
</style>
