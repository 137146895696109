<template>
  <div class="wrapper">

    <div class="itemdetailbox">
      <el-collapse v-model="activeNames">
        <el-collapse-item title="" name="1">
          <template slot="title">
            <span class="tit"><i class="el-icon-tickets"></i>项目详情</span>
          </template>
          <item-detail :editType="curItemEditType" :actuuid="actUuid" :editItemid="curEditItemid" ref="curItemDetail"></item-detail>
        </el-collapse-item>
      </el-collapse>
    </div>

    


    <div class="searchbox">
      <el-form :inline="true">
        <el-form-item>
          <el-button type="primary" @click="refreshFn"><i class="el-icon-refresh"></i> 刷新</el-button>
          <el-button v-if="listType === ''" :disabled="activityFlag === '1'" type="primary" @click="addActivityInfo">新增项目信息</el-button>
          <span v-if="activityFlag === '1'" style="font-size: 12px; margin-left: 10px; color: #f00;">如需操作，请先下架活动！</span>
        </el-form-item>
      </el-form>
    </div>

    

    <el-table :data="tableData" stripe>
      <el-table-column prop="joinnum" label="允许参与人数" width="110"></el-table-column>
      <el-table-column label="场地名称" width="110">
        <template slot-scope="scope">
          <span v-if="scope.row.zzareaname">{{scope.row.zzareaname}}</span>
          <span v-else>/</span>
        </template>
      </el-table-column>
      <el-table-column label="是否需要消费现金" width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.moneyflag == 0">否</span>
          <span v-if="scope.row.moneyflag == 1">是</span>
        </template>
      </el-table-column>
      <el-table-column label="消费金额（元）" width="110">
        <template slot-scope="scope">
          <span v-if="scope.row.moneyflag == 0">/</span>
          <span v-else>{{scope.row.moneynum / 100}}</span>
        </template>
      </el-table-column>

      <el-table-column label="是否需要扣除积分" width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.pointflag == 0">否</span>
          <span v-if="scope.row.pointflag == 1">是</span>
        </template>
      </el-table-column>
      <el-table-column label="扣除积分数">
        <template slot-scope="scope">
          <span v-if="scope.row.pointflag == 0">/</span>
          <span v-else>{{scope.row.pointnums}}</span>
        </template>
      </el-table-column>
      

      <el-table-column label="是否有积分奖励" width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.addflag == 0">否</span>
          <span v-if="scope.row.addflag == 1">是</span>
        </template>
      </el-table-column>
      <el-table-column  label="奖励积分数">
        <template slot-scope="scope">
          <span v-if="scope.row.addflag == 0">/</span>
          <span v-else>{{scope.row.addpoint}}</span>
        </template>
      </el-table-column>

      <el-table-column label="参与用户类型" width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.joinflag == 0">全部用户</span>
          <span v-if="scope.row.joinflag == 1">实名用户</span>
        </template>
      </el-table-column>

      <el-table-column label="是否允许重复参与此" width="130">
        <template slot-scope="scope">
          <span v-if="scope.row.allowrepeat == 0">允许</span>
          <span v-if="scope.row.allowrepeat == 1">不允许</span>
        </template>
      </el-table-column>

      <el-table-column label="是否允许一次报名多人" width="140">
        <template slot-scope="scope">
          <span v-if="scope.row.allowmultiple == 0">允许</span>
          <span v-if="scope.row.allowmultiple == 1">不允许</span>
        </template>
      </el-table-column>

      
      <el-table-column label="一次报名人数上限" width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.allowmultiple == 1">/</span>
          <span v-else>{{scope.row.applylimit}}</span>
        </template>
      </el-table-column>

      <el-table-column prop="begintime" label="活动开始时间" width="140"></el-table-column>

      <el-table-column prop="endtime" label="活动结束时间" width="140"></el-table-column>


      <el-table-column label="操作" fixed="right" width="200" align="center" v-if="listType == 'check'">
        <template slot-scope="scope">
          <el-button @click="activityInfoDetail(scope.row)" type="primary">信息详情</el-button>
        </template>
      </el-table-column>

      <el-table-column label="操作" fixed="right" width="260" align="center" v-else>
        <template slot-scope="scope">
          <el-button @click="editActivityInfo(scope.row)" type="primary">修改</el-button>
          <el-button @click="deleteFn(scope.row)" type="danger" :disabled="activityFlag === '1'">删除</el-button>
          <el-button @click="userlist(scope.row)" type="success">报名列表</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="paginationbox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="fenye.pagesizes"
        :page-size="fenye.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="fenye.totalnum"
      >
      </el-pagination>
    </div>


    <!-- 新增信息，可批量新增 -->
    <el-dialog append-to-body title="新增信息" :close-on-click-modal='false' :visible.sync="dialogVisible"  width="1000px" :before-close="handleClose">
      <new-info  :itemDetail="itemDetail" @refreshInfosList="refreshInfosList" ref="info"></new-info>
    </el-dialog>

    <!-- 修改信息，只能单个修改 -->
    <el-dialog append-to-body title="修改信息" :close-on-click-modal='false' :visible.sync="updateDialogVisible"  width="1000px" :before-close="updatehandleClose">
      <edit-info :updateInfoId="updateInfoId" :itemDetail="itemDetail" :activityFlag="activityFlag" @updateRefreshInfosList="updateRefreshInfosList" ref="updateInfo"></edit-info>
    </el-dialog>

    <!-- 查看信息详情 -->
    <el-dialog append-to-body title="信息详情" :itemDetail="itemDetail" :close-on-click-modal='false' :visible.sync="infoDetailDialogVisible"  width="1000px">
      <edit-info :updateInfoId="updateInfoId" :editType="'detail'" ref="infoDetail"></edit-info>
    </el-dialog>

    <!-- 报名列表 -->
    <el-dialog append-to-body title="报名列表" :close-on-click-modal='false' :visible.sync="applyUserFlag" v-if="applyUserFlag" width="70%">
      <applyuser-list :infoId="infoId"></applyuser-list>
    </el-dialog>

    

  </div>
</template>

<script>
import {
  itemInfoListQuery,
  itemInfoDelete,
} from "@/api/acitvity/index.js";
import newInfo from './newInfo.vue'
import editInfo from './editInfo.vue'
import itemDetail from '../items/newItem'
import applyuserList from '../../applyUserList'
import { getDictItem } from '@/api/merchantManage'
export default {
  name: "",
  components: {
    newInfo,
    editInfo,
    itemDetail,
    applyuserList
  },
  props: {
    itemid:{
      type: Number,
      default: null
    },
    listType: {
      type: String,
      default: ''
    },
    activityFlag: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      tableData: [],
      currentPage: 1,
      fenye: {
        totalnum: 0,
        pagesizes: [10, 20, 30, 40, 50],
        pagesize: 10
      },
      dialogVisible: false,
      itemDetail: {

      },
      //更新信息的字段
      updateDialogVisible: false,
      updateInfoId: '',
      //查看信息详情的字段
      infoDetailDialogVisible: false,
      //当前项目详情
      curItemEditType: '',
      curEditItemid: null,
      activeNames: ['1'],
      //参与报名列表字段
      applyUserFlag: false,
      infoId:'',
      actUuid: '',
      payTypes: []
    };
  },
  watch: {
    /* itemid(newval,oldval){
      if(newval){
        this.currentPage = 1;
        this.getList();
        this.curItemDetail();
      }
    } */
  },
  created() {},
  mounted() {
    // this.getList();
    this.getPayType();
  },
  computed: {},
  methods: {
    getPayType(){
      getDictItem({
        type: 'payment_method'
      }).then(res => {
        if (res.result == 200) {
          this.payTypes = res.detail;
        } else {
          this.$message.warning(res.description)
        }
      })
    },
    //查看项目详情
    curItemDetail(actUuid){
      this.actUuid = actUuid;
      this.curItemEditType = 'detail';
      this.curEditItemid = this.itemid;
      this.$nextTick(() => {
        this.$refs.curItemDetail.getItemDetail();
      })
    },
    //关闭新增项目信息弹框
    handleClose(done) {
      this.$refs.info.resetForm('ruleForm');
      done();
    },
    //关闭悠项目信息弹框
    updatehandleClose(done){
      this.$refs.updateInfo.resetForm('ruleForm');
      done();
    },
    //获取列表
    getList() {
      itemInfoListQuery({
          userid: this.GLOBAL.adminId(),
          pageSize: this.fenye.pagesize,
          pageNum: this.currentPage,
          itemid: this.itemid,
      }).then(res=>{
        if(res.result == 200){
          let pageRes = res.detail.pageResult;
          this.fenye.totalnum = pageRes.totalRow;
          this.tableData = pageRes.list;
          this.itemDetail = res.detail.actActivityItem;
          // console.log(this.itemDetail)
        } else {
          this.$message.error(res.description);
        }
      })
    },
    //切换页面条数
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.fenye.pagesize = val;
      this.currentPage = 1;
      this.getList();
    },
    //切换分页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    //删除项目信息
    deleteFn(row){
      // console.log(row)
      this.$confirm('是否确认删除该项目信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        cancelButtonClass: "btn-custom-cancel",
        type: 'warning'
      }).then(() => {
        itemInfoDelete({
            userid: this.GLOBAL.adminId(),
            iteminfoid: row.iteminfoid
        }).then(res=>{
          if(res.result == 200){
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.currentPage = 1;
            this.getList();
          } else {
            this.$message.error(res.description);
          }
        })
      }).catch(()=>{

      })
    },
    //刷新数据
    refreshFn(){
      this.currentPage = 1;
      this.getList();
    },
    //新增时，刷新列表并关闭弹框
    refreshInfosList(){
      this.refreshFn();
      this.dialogVisible = false;
    },
    //修改时，刷新列表并关闭弹框
    updateRefreshInfosList(){
      this.refreshFn();
      this.updateDialogVisible = false;
    },
    //添加项目信息
    addActivityInfo(){
      this.dialogVisible = true;
      this.$nextTick(()=>{
        this.$refs.info.zzareaOptions = [];
        this.$refs.info.getZzarea();
      })
    },
    //编辑项目信息
    editActivityInfo(row){
      this.updateDialogVisible = true;
      this.updateInfoId = row.iteminfoid;
      this.$nextTick(()=>{
        this.$refs.updateInfo.getInfoDetail();
        this.$refs.updateInfo.getZzarea();
      })
    },
    //查看项目信息详情
    activityInfoDetail(row){
      this.infoDetailDialogVisible = true;
      this.updateInfoId = row.iteminfoid;
      this.$nextTick(()=>{
        this.$refs.infoDetail.getInfoDetail();
        this.$refs.infoDetail.getZzarea();
      })
    },
    //查看报名列表
    userlist(row){
      this.applyUserFlag = true;
      this.infoId = row.iteminfoid;
    }
    
  },
};
</script>

<style lang="scss" scoped>
.searchbox{
  margin: 0 10px;
}
.paginationbox {
  text-align: right;
  margin-top: 20px;
}


.itemdetailbox{
  margin-bottom: 20px;
  margin-top: -20px;
  .el-icon-tickets{
    margin-right: 4px;
  }
  .tit{
    padding: 0 10px;
    font-size: 16px;
    color: #318fdf;
  }
}
</style>