<template>
  <div class="wrapper">
    <div class="searchbox">
      <el-form :inline="true">
        <el-form-item>
          <el-button type="success" v-if="tableData.length>0" @click="exportXcle"><i class="el-icon-download"></i>excel导出</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-table :data="tableData" stripe>
      <el-table-column prop="nickname" label="昵称"> </el-table-column>
      <el-table-column prop="phone" label="手机号"> </el-table-column>
      <el-table-column prop="joincontents" label="报名字段项"> </el-table-column>
      <el-table-column prop="jointime" width="120px" label="报名时间"> </el-table-column>
      <el-table-column prop="paypoint" label="扣除积分"> </el-table-column>
      <el-table-column label="消费金额（元）">
        <template slot-scope="scope">
          <span>{{ scope.row.payrmb/100 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="支付状态">
        <template slot-scope="scope">
          <span v-if="scope.row.paystate === '0'">已支付</span>
          <span v-else-if="scope.row.paystate === '1'">未支付</span>
          <span v-else>/</span>
        </template>
      </el-table-column>
      <el-table-column prop="joinflagname" width="160px" label="报名状态"></el-table-column>
      <el-table-column prop="backone" width="160px" label="取消原因"></el-table-column>
    </el-table>

    <div class="paginationbox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="fenye.pagesizes"
        :page-size="fenye.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="fenye.totalnum"
      >
      </el-pagination>
    </div>

    <el-table :data="tableDataExport" v-show="false" stripe id="exportTable">
      <el-table-column prop="nickname" label="昵称"> </el-table-column>
      <el-table-column prop="phone" label="手机号"> </el-table-column>
      <el-table-column prop="joincontents" label="报名字段项"> </el-table-column>
      <el-table-column prop="jointime" width="120px" label="报名时间"> </el-table-column>
      <el-table-column prop="paypoint" label="扣除积分"> </el-table-column>
      <el-table-column label="消费金额（元）">
        <template slot-scope="scope">
          <span>{{ scope.row.payrmb/100 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="支付状态">
        <template slot-scope="scope">
          <span v-if="scope.row.paystate === '0'">已支付</span>
          <span v-else-if="scope.row.paystate === '1'">未支付</span>
          <span v-else>/</span>
        </template>
      </el-table-column>
      <el-table-column prop="joinflagname" width="160px" label="报名状态"></el-table-column>
      <el-table-column prop="backone" width="160px" label="取消原因"></el-table-column>
    </el-table>
  </div>
</template>

<script>
import FileSaver from 'file-saver'
import XLSX from 'xlsx'
import { listQuery } from "@/api/acitvity/index.js";
export default {
  name: "",
  components: {},
  props: {
    infoId: {
      type: Number,
      default: null,
    }
  },
  data() {
    return {
      tableData: [],
      tableDataExport:[],
      fenye: {
        totalnum: 0,
        pagesizes: [10, 20, 30, 40, 50],
        pagesize: 10,
      },
      currentPage: 1,
    };
  },
  watch: {},
  created() {
    this.getList();
  },
  mounted() {
    
  },
  computed: {},
  methods: {
    getList() {
      listQuery({
        userid: this.GLOBAL.adminId(),
        pageSize: this.fenye.pagesize,
        pageNum: this.currentPage,
        iteminfoid: this.infoId,
      }).then((res) => {
        if (res.result == 200) {
          let detail = res.detail;
          this.fenye.totalnum = detail.totalRow;
          this.tableData = detail.list;
        } else {
          this.$message.error(res.description);
        }
      });
    },
    //切换页面条数
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.fenye.pagesize = val;
      this.currentPage = 1;
      this.getList();
    },
    //切换分页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    //导出
    exportXcle(){
      listQuery({
        userid: this.GLOBAL.adminId(),
        pageSize: this.fenye.pagesize,
        pageNum: this.currentPage,
        iteminfoid: this.infoId,
      }).then((res) => {
        if (res.result == 200) {
          let detail = res.detail;
          this.tableDataExport = detail.list;
          let downloadTimer = setTimeout(()=>{
            clearTimeout(downloadTimer);
            let xlsxParam = { raw: true };
            let wb = XLSX.utils.table_to_book(document.querySelector("#exportTable"),xlsxParam);
            let wbout = XLSX.write(wb, {
              bookType: "xlsx",
              bookSST: true,
              type: "array",
            });
            try {
              FileSaver.saveAs(
                new Blob([wbout], { type: "application/octet-stream" }),"参与列表.xlsx"
              );
            } catch (e) {
              if (typeof console !== "undefined") console.log(e, wbout);
            }
            return wbout;
          },10)
        } else {
          this.$message.error(res.description);
        }
      });
		}	
  },
};
</script>

<style lang="scss" scoped>
.paginationbox {
  text-align: right;
  margin-top: 20px;
}
</style>