<template>
	<div class="wrapper">
		<div class="searchbox">
			<el-form :inline="true">
				<el-form-item label="项目名称">
					<el-select v-model="search.itemid" @change="chooseItem" clearable placeholder="请选择项目">
						<el-option v-for="item in items" :key="item.itemid" :label="item.title" :value="item.itemid">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="活动时间" v-if="search.itemid">
					<el-select v-model="search.iteminfoid" clearable placeholder="请选择活动时间">
						<el-option v-for="item in itemInfos" :key="item.iteminfoid"
							:label="item.begintime + '~' + item.endtime" :value="item.iteminfoid">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSearch">查询</el-button>
					<el-button type="success" v-if="tableData.length>0" @click="exportXcle"><i
							class="el-icon-download"></i>excel导出</el-button>
				</el-form-item>
			</el-form>
		</div>

		<el-table :data="tableData" stripe>
			<el-table-column prop="name" label="姓名" align="center"> </el-table-column>
			<el-table-column prop="phone" label="电话" width="100px" align="center"> </el-table-column>
			<el-table-column prop="nickname" label="昵称" width="100px" align="center"> </el-table-column>
			<el-table-column prop="joincontents" label="报名字段项" show-overflow-tooltip align="center">
			</el-table-column>
			<el-table-column prop="jointime" width="160px" label="报名时间" align="center">
			</el-table-column>
			<el-table-column prop="paypoint" label="扣除积分" align="center"></el-table-column>
			<el-table-column label="消费金额（元）" width="150px" align="center">
				<template slot-scope="scope">
					<span>{{ scope.row.payrmb ? scope.row.payrmb / 100 : "/" }}</span>
				</template>
			</el-table-column>
			<el-table-column label="活动起止时间" width="300px" align="center">
				<template slot-scope="scope">
					<span>{{ scope.row.begintime }} ~ {{ scope.row.endtime }}</span>
				</template>
			</el-table-column>
			<el-table-column prop="address" label="支付状态" align="center">
				<template slot-scope="scope">
					<span v-if="scope.row.paystate === '0'">已支付</span>
					<span v-else-if="scope.row.paystate === '1'">未支付</span>
					<span v-else>/</span>
				</template>
			</el-table-column>
			<el-table-column prop="joinflagname" width="160px" label="报名状态" align="center"></el-table-column>
			<el-table-column prop="backone" width="160px" label="取消原因" align="center"></el-table-column>
		    <el-table-column label="操作" fixed="right" width="100" align="center">
		      <template slot-scope="scope">
		        <el-button :disabled="scope.row.joinflag!=0" @click="joincancel(scope.row.activityjoinid)" type="danger">取消报名</el-button>
		      </template>
		    </el-table-column>
		</el-table>
		
		<div class="paginationbox">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="currentPage" :page-sizes="fenye.pagesizes" :page-size="fenye.pagesize"
				layout="total, sizes, prev, pager, next, jumper" :total="fenye.totalnum">
			</el-pagination>
		</div>


		<el-table v-show="false" :data="tableDataExport" stripe id="exportTable">
			<el-table-column prop="name" label="姓名"></el-table-column>
			<el-table-column prop="phone" label="电话" width="100px"></el-table-column>
			<el-table-column prop="nickname" label="昵称" width="100px"></el-table-column>
			<el-table-column prop="joincontents" label="报名字段项" show-overflow-tooltip>
			</el-table-column>
			<el-table-column prop="jointime" width="160px" label="报名时间">
			</el-table-column>
			<el-table-column prop="paypoint" label="扣除积分"> </el-table-column>
			<el-table-column label="消费金额（元）">
				<template slot-scope="scope">
					<span>{{ scope.row.payrmb ? scope.row.payrmb / 100 : "/" }}</span>
				</template>
			</el-table-column>
			<el-table-column label="活动起止时间" width="300px">
				<template slot-scope="scope">
					<span>{{ scope.row.begintime }} ~ {{ scope.row.endtime }}</span>
				</template>
			</el-table-column>
			<el-table-column prop="address" label="支付状态">
				<template slot-scope="scope">
					<span v-if="scope.row.paystate === '0'">已支付</span>
					<span v-else-if="scope.row.paystate === '1'">未支付</span>
					<span v-else>/</span>
				</template>
			</el-table-column>
			<el-table-column prop="joinflagname" width="160px" label="报名状态"></el-table-column>
			<el-table-column prop="backone" width="160px" label="取消原因"></el-table-column>
		</el-table>
	</div>
</template>

<script>
	import FileSaver from 'file-saver'
	import XLSX from 'xlsx'
	import {
		activityjoinPage,
		activityItemQuery,
		itemInfoListQuery,
		joincancel,
	} from "@/api/acitvity/index.js";
	export default {
		name: "",
		components: {},
		props: {
			actuuid: {
				type: String,
				default: "",
			},
		},
		data() {
			return {
				tableData: [],
				tableDataExport: [],
				items: [],
				itemInfos: [],
				fenye: {
					totalnum: 0,
					pagesizes: [10, 20, 30, 40, 50],
					pagesize: 10,
				},
				currentPage: 1,
				search: {
					itemid: "",
					iteminfoid: "",
				},
			};
		},
		watch: {},
		created() {
			this.getItems();
			this.getList();
		},
		mounted() {},
		computed: {},
		methods: {
		    //取消报名
		    joincancel(id){
		        this.$prompt('请输入取消理由', '提示', {
		          confirmButtonText: '确定取消',
		          cancelButtonText: '取消',
		          inputPattern: /\S/,
		          inputErrorMessage: '请输入取消理由'
		        }).then(({ value }) => {
		          joincancel({
		            userid: this.GLOBAL.adminId(),
		            joinid: id,
		            backone: value
		          }).then(res=>{
		            if(res.result == 200){
		              this.$message.success('取消报名成功')
		              this.onSearch();
		            } else {
		              this.$message.error(res.description);
		            }
		          })
		        }).catch(() => {
		              
		        });
		    },
			getList() {
				activityjoinPage({
					userid: this.GLOBAL.adminId(),
					pageSize: this.fenye.pagesize,
					pageNum: this.currentPage,
					actuuid: this.actuuid,
					itemid: this.search.itemid,
					iteminfoid: this.search.iteminfoid,
				}).then((res) => {
					if (res.result == 200) {
						let detail = res.detail;
						this.fenye.totalnum = detail.totalRow;
						this.tableData = detail.list;
					} else {
						this.$message.error(res.description);
					}
				});
			},
			//获取项目
			getItems() {
				activityItemQuery({
					userid: this.GLOBAL.adminId(),
					pageSize: 1000,
					pageNum: 1,
					actuuid: this.actuuid,
				}).then((res) => {
					if (res.result == 200) {
						this.items = res.detail.list;
					} else {
						this.$message.error(res.description);
					}
				});
			},
			//切换页面条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.fenye.pagesize = val;
				this.currentPage = 1;
				this.getList();
			},
			//切换分页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
			//搜索
			onSearch() {
				this.currentPage = 1;
				this.getList();
			},
			//记录导出
			exportFn() {

			},
			//获取项目下的项目信息
			chooseItem(itemid) {
				this.search.iteminfoid = "";
				if (itemid) {
					itemInfoListQuery({
						userid: this.GLOBAL.adminId(),
						pageSize: 1000,
						pageNum: 1,
						itemid: itemid,
					}).then((res) => {
						if (res.result == 200) {
							this.itemInfos = res.detail.pageResult.list;
							console.log(this.itemInfos);
						} else {
							this.$message.error(res.description);
						}
					});
				}
			},
			//导出
			exportXcle() {
				activityjoinPage({
					userid: this.GLOBAL.adminId(),
					pageSize: 99999,
					pageNum: 1,
					actuuid: this.actuuid,
					itemid: this.search.itemid,
					iteminfoid: this.search.iteminfoid,
				}).then((res) => {
					if (res.result == 200) {
						let detail = res.detail;
						this.tableDataExport = detail.list;
						let downloadTimer = setTimeout(() => {
							clearTimeout(downloadTimer);
							let xlsxParam = {
								raw: true
							};
							let wb = XLSX.utils.table_to_book(document.querySelector("#exportTable"),
								xlsxParam);
							let wbout = XLSX.write(wb, {
								bookType: "xlsx",
								bookSST: true,
								type: "array",
							});
							try {
								FileSaver.saveAs(
									new Blob([wbout], {
										type: "application/octet-stream"
									}), "参与列表.xlsx"
								);
							} catch (e) {
								if (typeof console !== "undefined") console.log(e, wbout);
							}
							return wbout;
						}, 10)
					} else {
						this.$message.error(res.description);
					}
				});
			}

		}
	};
</script>

<style lang="scss" scoped>
	.searchbox {
		margin: 0 10px;
	}

	.paginationbox {
		text-align: right;
		margin-top: 20px;
	}
</style>