<template>
  <div class="wrapper">
    <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="140px"
        :disabled="editType == 'detail'"
      >
        
        <el-form-item label="项目名称" prop="title">
          <el-input v-model="ruleForm.title" placeholder="请输入项目名称"></el-input>
        </el-form-item>

        <el-form-item label="项目图片" prop="iteampic">
          <el-upload
            ref="upload"
            action=""
            accept="image/png, image/jpeg, image/.jpg"
            :limit="1"
            list-type="picture-card"
            :on-exceed="handleExceed"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-change="handleChange"
            :file-list="fileList"
            :auto-upload="false">
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog title="图片预览" append-to-body :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
          </el-dialog>
          <el-input v-show="false" v-model="ruleForm.iteampic"></el-input>
        </el-form-item>
        
        <el-form-item label="选择时间参与方式" prop="byday">
          <el-radio-group v-model="ruleForm.byday">
            <el-radio :label="0"  v-if="actType != 1">按照时间段参与</el-radio>
            <el-radio :label="1">按照天参与</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="描述信息" prop="content">
          <el-input type="textarea" placeholder="请输入描述信息" v-model="ruleForm.content"></el-input>
        </el-form-item>

        <!-- <el-form-item label="是否需要实名" prop="joinflag">
          <el-radio-group v-model="ruleForm.joinflag">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item> -->

        <el-form-item label="确认报名提示信息">
          <el-input v-model="ruleForm.doingmsg" placeholder="默认为是否确认报名？"></el-input>
        </el-form-item>
        

        <el-form-item label="所属社区" prop="community">
          <el-checkbox-group v-model="ruleForm.community">
            <el-checkbox v-for="item in communitys" :key="item.communitycode" :label="item.communitycode">{{item.communityname}}</el-checkbox>
          </el-checkbox-group>

          <!-- <el-radio-group v-model="ruleForm.community">
            <el-radio  v-for="item in communitys" :key="item.communitycode" :label="item.communitycode">{{item.communityname}}</el-radio>
          </el-radio-group> -->
        </el-form-item>

        <el-form-item>
          <el-button type="primary" v-if="editType == 'add'"  :disabled="buttonDisabled" @click="submitForm('ruleForm')"
            >创建项目</el-button
          >
          <el-button type="primary" :disabled="activityFlag === '1' || buttonDisabled" v-if="editType == 'update'" @click="submitForm('ruleForm')"
            >修改项目</el-button
          >
          <el-button v-if="editType == 'add'" @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>

      </el-form>
  </div>
</template>

<script>
import {
  itemDetail,
  activityItemUpdate,
  activityItemAdd,
  activityItemArea,
  activityAreaDetail,
  activityDetail
} from "@/api/acitvity/index.js";
import { communityOfUser } from '@/api/system';
export default {
  name:"",
  components:{},
  props:{
    actuuid:{
      type: String,
      default: ''
    },
    editType:{
      type: String,
      default: 'add'
    },
    editItemid: {
      type: Number,
      default: null
    },
    activityFlag: {
      type: String,
      default: ''
    }
  },
  data(){
    return {
      fileList: [],
      dialogImageUrl: '',
      dialogVisible: false,
      ruleForm: {
        iteampic:'',
        content: '',
        title: '',
        byday: null,
        // joinflag:'',
        doingmsg: '',
        community: []
      },
      communitys:[],
      rules: {
          title: [
            { required: true, message: '请输入活动名称', trigger: 'blur' }
          ],
          byday: [
            { required: true, message: '请选择选择时间参与方式', trigger: 'change' }
          ],
          joinflag: [
            { required: true, message: '请选择是否需要实名', trigger: 'change' }
          ],
          iteampic: [
            { required: true, message: '请上传项目图', trigger: 'change' }
          ],
          content: [
            { required: true, message: '请输入活动内容', trigger: 'change' }
          ],
          community: [
            { required: true, message: '请选择活动所属社区', trigger: 'change' }
          ]
        },
        buttonDisabled: false,
        actType: null
    }
  },
  watch:{},
  created(){},
  mounted(){
    // this.getCommunityOfUser();
  },
  computed:{},
  methods:{
    isAdd(){
      //新增时，社区默认为活动的社区
      if(this.editType == 'add'){
        activityAreaDetail({
          userid: this.GLOBAL.adminId(),
          actuuid: this.actuuid
        }).then(res=>{
          if(res.result == 200){
            this.communitys =  res.detail;
            // console.log(res)
            // this.ruleForm.community = res.detail.communitycode;
          } else {
            this.$message.error(res.description);
          }
        })
      }
      //获取活动类型，用来隐藏“时间段参与”选项
      activityDetail({
        userid: this.GLOBAL.adminId(),
        actuuid: this.actuuid,	
      }).then(res=>{
        if(res.result == 200){
          let detail = res.detail;
          this.actType = detail.acttype;
          //预约活动时，默认选中“按天参与”
          if(this.actType == 1){
            this.ruleForm.byday = 1
          }
        }else{
          this.$message.warning(res.description)
        }
      })

    },
    //获取当前登录用户的管辖社区
    getCommunityOfUser(){
      communityOfUser({
        userid: this.GLOBAL.adminId()
      }).then(res=>{
        if(res.result == 200){
          this.communitys =  res.detail;
        } else {
          this.$message.error(res.description);
        }
      })
    },
    //列表图 文件状态改变时的钩子
    handleChange(file, fileList) {
      const isLt2M = file.size / 1024 / 1024  < 2;
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
        this.fileList = [];
        return false;
      }

      let formData = new FormData();
			formData.append('upfilelist', file.raw);
      this.$axios.post('/fileutil/Miniupload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res=> {
          if (res.data.result == 200) {
            // console.log(res.data.detail)
            this.ruleForm.iteampic = res.data.detail
          } else {
            this.$message.error(res.data.description);
            this.fileList = []
          }
      })
      
    },
    //列表图 文件列表移除文件时的钩子
    handleRemove(file, fileList) {
        this.ruleForm.iteampic = '';
    },
    //文件超出个数限制时的钩子
    handleExceed(files,fileList){
        this.$message.warning('只能选择1个文件!');
    },
    //图片预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    //修改或新增项目提交
    submitForm(formName) {
      this.buttonDisabled = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            userid: this.GLOBAL.adminId(),
            actuuid: this.actuuid,
            title: this.ruleForm.title,
            iteampic: this.ruleForm.iteampic,
            content: this.ruleForm.content,
            byday: this.ruleForm.byday,
            acttype: this.ruleForm.acttype,
            // joinflag: this.ruleForm.joinflag,
            doingmsg: this.ruleForm.doingmsg
          }

          
          // console.log(this.editType)
          if(this.editType == 'add'){
            activityItemAdd(data).then(res=>{
              this.buttonDisabled = false;
              if(res.result == 200){
                // console.log(res)
                let itemId = res.detail;
                this.contactActivityItemArea(itemId);
              }else{
                this.$message.warning(res.description)
              }
            }).catch(err=>{
              this.buttonDisabled = false;
            })
          }else if(this.editType == 'update'){
            data.itemid = this.editItemid;
            activityItemUpdate(data).then(res=>{
              this.buttonDisabled = false;
              if(res.result == 200){
                // console.log(res)
                this.contactActivityItemArea(this.editItemid);
              }else{
                this.$message.warning(res.description)
              }
            }).catch(err=>{
              this.buttonDisabled = false;
            })
          }
          
        } else {
          this.buttonDisabled = false;
          console.log('error submit!!');
          return false;
        }
      });
    },
    //重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //关联项目区域
    contactActivityItemArea(itemid){
        activityItemArea({
          userid: this.GLOBAL.adminId(),
          isadd: this.editType == 'add'? 1: 0,
          actuuid: this.actuuid,
          itemid: itemid,
          communitycodes: this.ruleForm.community.join(','),
          // communitycodes: this.ruleForm.community
        }).then(res=>{
            if(res.result == 200){
              if(this.editType == 'add'){
                this.$message.success('创建成功');
              }else{
                this.$message.success('修改成功');
              }
              this.resetForm('ruleForm');
              this.fileList = [];
              this.$emit('refreshItemsList')
            }else{
              this.$message.warning(res.description)
            }
        }).catch(err=>{

        })
    },
    //获取项目
    getItemDetail(){
      //获取所属社区
      activityAreaDetail({
        userid: this.GLOBAL.adminId(),
        actuuid: this.actuuid
      }).then(res=>{
        if(res.result == 200){
          this.communitys =  res.detail;
        } else {
          this.$message.error(res.description);
        }
      })


      itemDetail({
        userid: this.GLOBAL.adminId(),
        itemid: this.editItemid
      }).then(res=>{
        if(res.result == 200){
          let detail = res.detail;
          let comCode = detail.codes?detail.codes:[];
          this.ruleForm = {
            iteampic: detail.iteampic,
            content: detail.content,
            title: detail.title,
            byday: detail.byday,
            // joinflag: parseInt(detail.joinflag),
            doingmsg: detail.doingmsg,
            community: comCode.length>0 ? comCode.split(','):[]
            // community: detail.codes
          }
          this.fileList = detail.path ? [{ url: `${detail.path}`}]:[];

        }else{
          this.$message.warning(res.description)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  
</style>