<template>
	<div class="wrapper">
		<div class="content">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px"
				:disabled="activityEditType == 'detail'">
				<el-form-item label="列表图片" prop="infopic">
					<el-upload ref="upload" action="" accept="image/png, image/jpeg, image/.jpg" :limit="1"
						list-type="picture-card" :on-exceed="handleExceed" :on-preview="handlePictureCardPreview"
						:on-remove="handleRemove" :on-change="handleChange" :file-list="fileList" :auto-upload="false">
						<i class="el-icon-plus"></i>
					</el-upload>
					<el-dialog title="图片预览" append-to-body :visible.sync="dialogVisible">
						<img width="100%" :src="dialogImageUrl" alt="">
					</el-dialog>
					<el-input v-show="false" v-model="ruleForm.infopic"></el-input>
				</el-form-item>

				<el-form-item label="活动名称" prop="actname">
					<el-input v-model="ruleForm.actname"></el-input>
				</el-form-item>

				<el-form-item label="活动展示图片" prop="showpic">
					<el-upload ref="upload1" action="" accept="image/png, image/jpeg, image/.jpg" :limit="1"
						list-type="picture-card" :class="{hide: ruleForm.showpic}" :on-exceed="handleExceed"
						:on-preview="handlePictureCardPreview" :on-remove="handleRemoveUnder"
						:on-change="handleChangeUnder" :file-list="fileListUnder" :auto-upload="false">
						<i class="el-icon-plus"></i>
					</el-upload>
					<el-dialog title="图片预览" append-to-body :visible.sync="dialogVisible">
						<img width="100%" :src="dialogImageUrl" alt="">
					</el-dialog>
					<el-input v-show="false" v-model="ruleForm.showpic"></el-input>
				</el-form-item>


				<el-form-item label="活动类型" prop="acttype">
					<el-select v-model="ruleForm.acttype" disabled placeholder="请选择活动区域">
						<el-option v-for="item in activityTypes" :label="item.label" :value="item.value"
							:key="item.value"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="活动分类" prop="category">
					<el-select v-model="ruleForm.category" placeholder="请选择活动分类" clearable>
						<el-option v-for="item in categoryTypes" :label="item.name" :value="item.uuid"
							:key="item.uuid"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="活动内容" prop="actcontent">
					<vue-editor id="editor" use-custom-image-handler @image-added="handleImageAdded"
						v-model="ruleForm.actcontent" />
				</el-form-item>

				<!-- <el-form-item label="活动时间" prop="date">
          <el-date-picker
            v-model="ruleForm.date"
            @change="dateChange"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item> -->

				<el-form-item label="活动地址" prop="address">
					<el-input v-model="ruleForm.address"><el-button slot="append" icon="el-icon-location-outline"
							@click="openMapFn"></el-button></el-input>
				</el-form-item>

				<el-form-item label="活动所属社区" prop="community">
					<el-checkbox-group v-model="ruleForm.community">
						<el-checkbox v-for="item in communitys" :key="item.levelcode"
							:label="item.levelcode">{{item.name}}</el-checkbox>
					</el-checkbox-group>

					<!-- <el-radio-group v-model="ruleForm.community">
            <el-radio  v-for="item in communitys" :key="item.levelcode" :label="item.levelcode">{{item.name}}</el-radio>
          </el-radio-group> -->
				</el-form-item>

				<!-- <el-form-item label="活动查看次数" prop="lookcount">
          <el-input oninput="value=value.replace(/[^\d]/g,'')" v-model="ruleForm.lookcount"></el-input>
        </el-form-item> -->

				<el-form-item label="是否置顶" prop="stickflag">
					<el-radio-group v-model="ruleForm.stickflag">
						<el-radio :label="1">是</el-radio>
						<el-radio :label="0">否</el-radio>
					</el-radio-group>
				</el-form-item>

				<el-form-item>
					<el-button v-if="activityEditType=='add'" type="primary"
						@click="submitForm('ruleForm')">创建活动</el-button>
					<el-button v-if="activityEditType=='update'" type="primary" :disabled="activityFlag ==='1'"
						@click="submitForm('ruleForm')">修改活动</el-button><span v-if="activityFlag ==='1'"
						style="margin-left: 10px; font-size:12px; color: #f00;">如需操作，请先下架活动！</span>
					<el-button v-if="activityEditType=='add'" @click="resetForm('ruleForm')">重置</el-button>
				</el-form-item>
			</el-form>
		</div>

		<event-map ref="eventMap" @getEventMapData="getEventMapData"></event-map>
	</div>
</template>


<script>
	import {
		VueEditor
	} from "vue2-editor";
	import {
		activityMainApi,
		activityAreaApi,
		activityDetail,
		activityKind
	} from '@/api/acitvity/index.js'
	import {
		communityOfUser
	} from '@/api/system';
	import eventMap from './eventmap.vue'
	import dicts from '@/utils/dictionary'
	export default {
		name: "",
		components: {
			VueEditor,
			eventMap
		},
		props: {
			activityEditType: {
				type: String,
				default: 'add'
			},
			updateActuuid: {
				type: String,
				default: ''
			},
			activityFlag: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				activityTypes: dicts.activityType,
				categoryTypes: [],
				dialogImageUrl: '',
				dialogVisible: false,
				fileList: [],
				fileListUnder: [],

				ruleForm: {
					actuuid: '',
					actname: '',
					actcontent: '',
					showpic: '',
					infopic: '',
					acttype: this.$route.query.type, //acttype为2时，是公告活动，可直接创建活动
					date: [],
					category: '',
					begintime: '',
					endtime: '',
					community: [],
					address: '',
					intxy: '',
					stickflag: 0,
					lookcount: 0
				},
				communitys: [],
				rules: {
					actname: [{
						required: true,
						message: '请输入活动名称',
						trigger: 'blur'
					}],
					/* date: [
					  { required: true, message: '请选择日期', trigger: 'change' }
					], */
					category: [{
						required: true,
						message: '请选择活动分类',
						trigger: 'change'
					}],
					address: [{
						required: true,
						message: '请输入活动地址',
						trigger: 'change'
					}],
					infopic: [{
						required: true,
						message: '请上传列表图',
						trigger: 'change'
					}],
					showpic: [{
						required: true,
						message: '请上传活动展示图',
						trigger: 'change'
					}],
					actcontent: [{
						required: true,
						message: '请输入活动内容',
						trigger: 'change'
					}],
					community: [{
						required: true,
						message: '请选择活动所属社区',
						trigger: 'change'
					}],
					lookcount: [{
						required: true,
						message: '请输入查看次数',
						trigger: 'change'
					}]
				}
			}
		},
		watch: {},
		created() {},
		mounted() {
			this.getCommunityOfUser();
			this.getActivityKinds();
		},
		computed: {},
		methods: {
			//获取地址和经纬度
			getEventMapData(data) {
				console.log(data)
				this.ruleForm.address = data[2];
				this.ruleForm.intxy = data[0] + ',' + data[1];
			},
			//获取当前登录用户的管辖社区
			getCommunityOfUser() {
				communityOfUser({
					userid: this.GLOBAL.adminId()
				}).then(res => {
					if (res.result == 200) {
						this.communitys = res.detail;
					} else {
						this.$message.error(res.description);
					}
				})
			},
			//获取活动种类
			getActivityKinds() {
				activityKind({
					userid: this.GLOBAL.adminId()
				}).then(res => {
					if (res.result == 200) {
						this.categoryTypes = res.detail;
					} else {
						this.$message.error(res.description);
					}
				})
			},

			//列表图 文件状态改变时的钩子
			handleChange(file, fileList) {
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
					this.fileList = [];
					return false;
				}

				let formData = new FormData();
				formData.append('upfilelist', file.raw);
				this.$axios.post('/fileutil/Miniupload', formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then(res => {
					if (res.data.result == 200) {
						// console.log(res.data.detail)
						this.ruleForm.infopic = res.data.detail
					} else {
						this.$message.error(res.data.description);
						this.fileList = []
					}
				})

			},
			//列表图 文件列表移除文件时的钩子
			handleRemove(file, fileList) {
				this.ruleForm.infopic = '';
			},
			//文件超出个数限制时的钩子
			handleExceed(files, fileList) {
				this.$message.warning('只能选择1个文件!');
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},


			//活动展示图片 文件状态改变时的钩子
			handleChangeUnder(file, fileList) {
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
					this.fileListUnder = [];
					return false;
				}

				let formData = new FormData();
				formData.append('upfilelist', file.raw);
				this.$axios.post('/fileutil/Miniupload', formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then(res => {
					if (res.data.result == 200) {
						// console.log(res.data.detail)
						this.ruleForm.showpic = res.data.detail
					} else {
						this.$message.error(res.data.description);
						this.fileListUnder = [];
					}
				})


			},
			//活动展示图片 文件列表移除文件时的钩子
			handleRemoveUnder(file, fileList) {
				this.ruleForm.showpic = '';
			},
			//是否确认修改
			sureSubmit(formName) {

			},
			//提交活动
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						console.log(this.activityEditType)
						if (this.activityEditType == 'update') {
							this.$confirm('是否确认提交修改？', '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {
								this.addOrUpdateApi();
							}).catch(() => {

							});
						} else {
							this.addOrUpdateApi();
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			//调新增或编辑活动主体接口
			addOrUpdateApi() {
				let data = {
					userid: this.GLOBAL.adminId(),
					actname: this.ruleForm.actname,
					actcontent: this.ruleForm.actcontent,
					showpic: this.ruleForm.showpic,
					infopic: this.ruleForm.infopic,
					acttype: this.ruleForm.acttype,
					category: this.ruleForm.category,
					// begintime: this.ruleForm.begintime,
					// endtime: this.ruleForm.endtime,
					// rolecode: this.ruleForm.rolecode.join(','),
					address: this.ruleForm.address,
					intxy: this.ruleForm.intxy,
					stickflag: this.ruleForm.stickflag,
					lookcount: this.ruleForm.lookcount
				}
				if (this.activityEditType == 'add') {
					data.actuuid = '';
				} else if (this.activityEditType == 'update') {
					data.actuuid = this.updateActuuid
				}
				activityMainApi(data).then(res => {
					if (res.result == 200) {
						let actuuid = res.detail.actuuid;
						// console.log('actuuid', actuuid)
						// console.log('activityAreaApi', activityAreaApi)
						activityAreaApi({
							userid: this.GLOBAL.adminId(),
							isadd: this.activityEditType == 'add' ? '1' : '0',
							actuuid: actuuid,
							communitycodes: this.ruleForm.community.join(','),
						}).then(res => {
							console.log(this.activityEditType)
							console.log(this.ruleForm.acttype)
							if (res.result == 200) {
								//如果活动类型不是公告类型，提示用户到列表中进行下一步项目添加
								if (this.activityEditType == 'add') {
									if (this.ruleForm.acttype == 2) {
										this.$message.success('创建成功');
									} else {
										this.$message.success('活动创建成功，请在活动列表中添加活动项目');
									}
									this.resetForm('ruleForm');
									this.fileList = [];
									this.fileListUnder = [];
								} else if (this.activityEditType == 'update') {
									this.$message.success('修改成功');
									this.resetForm('ruleForm');
									this.fileList = [];
									this.fileListUnder = [];
									this.$emit('updateRefreshActsList')
								}

							} else {
								this.$message.warning(res.description)
							}
						}).catch(err => {

						})
					} else {
						this.$message.warning(res.description)
					}
				}).catch(err => {

				})
			},
			//重置表单
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			//日期选择
			dateChange(data) {
				if (data && data.length == 2) {
					this.ruleForm.begintime = data[0]
					this.ruleForm.endtime = data[1]
				} else {
					this.ruleForm.begintime = ''
					this.ruleForm.endtime = ''
				}
			},
			//富文本编辑器添加图片
			handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
				var formData = new FormData();
				formData.append("upfilelist", file);
				this.$axios({
						url: "/fileutil/MiniuploadObj",
						method: "POST",
						headers: {
							'Content-Type': 'multipart/form-data'
						},
						data: formData
					})
					.then(result => {
						if (result.data.result == 200) {
							// console.log(result)
							const url = result.data.detail[0].path; // Get url from response
							Editor.insertEmbed(cursorLocation, "image", url);
							resetUploader();
						} else {
							this.$message.warning(result.data.description)
						}

					})
					.catch(err => {
						console.log(err);
					});
			},
			//打开地图选地址
			openMapFn() {
				this.$refs.eventMap.dialogMapVisible = true;
			},
			//获取活动主体详情
			getActDetail() {
				activityDetail({
					userid: this.GLOBAL.adminId(),
					actuuid: this.updateActuuid,
				}).then(res => {
					if (res.result == 200) {
						let detail = res.detail;
						let community = detail.codes;
						let communityArr = community.split(','); //saj
						this.ruleForm = {
							actuuid: detail.actuuid,
							actname: detail.actname,
							actcontent: detail.actcontent,
							infopic: detail.infopic,
							showpic: detail.showpic,
							acttype: detail.acttype,
							category: detail.category,
							date: [detail.begintime, detail.endtime],
							begintime: detail.begintime,
							endtime: detail.endtime,
							community: communityArr,
							address: detail.address,
							intxy: detail.intxy,
							stickflag: detail.stickflag,
							lookcount: detail.lookcount
						}
						this.fileList = detail.infopicpath ? [{
							url: `${detail.infopicpath}`
						}] : []
						this.fileListUnder = detail.showpicpath ? [{
							url: `${detail.showpicpath}`
						}] : []

					} else {
						this.$message.warning(res.description)
					}
				})
			}
		},
	};
</script>

<style lang="scss" scoped>
	.wrapper {
		padding: 40px;

		.nextbtn {
			margin-top: 50px;
			text-align: center;
		}
	}

	::v-deep {
		.hide {
			&>div.el-upload--picture-card {
				display: none;
			}
		}

	}
</style>