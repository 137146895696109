<template>
	<div class="wrapper">

		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="180px">
			<el-row>
				<el-col :span="12">
					<el-form-item label="活动场地">
						<el-select v-model="ruleForm.zzareaid" clearable @change="getZzareaName" placeholder="请选择">
							<el-option v-for="item in zzareaOptions" :key="item.id" :label="item.areaname"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
			<!-- 0按照时间段参与 -->
			<el-row v-if="itemDetail.byday == 0">
				<el-col :span="12">
					<el-form-item label="活动起止时间" prop="activitytime">
						<el-date-picker @change="activitytimeChange" v-model="ruleForm.activitytime"
							type="datetimerange" range-separator="至" value-format="yyyy-MM-dd HH:mm:ss"
							start-placeholder="开始时间" end-placeholder="结束时间">
						</el-date-picker>
					</el-form-item>
				</el-col>
			</el-row>
			<!-- 1按照天参与 -->
			<el-row v-if="itemDetail.byday == 1">
				<el-col :span="20">
					<el-form-item label="活动日期" prop="days">
						<el-date-picker style="width: 100%" type="date" value-format="yyyy-MM-dd"
							v-model="ruleForm.days" placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row v-if="itemDetail.byday == 1">
				<el-row v-for="(timeOne,index) in ruleForm.times" :key="timeOne.key">
					<el-col :span="14">
						<el-form-item label="活动时间" :prop="'times.' + index + '.value'" :rules="{
                  required: true, message: '请选择活动时间', trigger: 'blur'
                }">
							<el-time-picker is-range v-model="timeOne.value" range-separator="至" value-format="HH:mm:ss"
								start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围">
							</el-time-picker>
						</el-form-item>
					</el-col>
					<el-col v-if="index == 0" :span="6" class="bigicon"><i @click="addTime()"
							class="el-icon-circle-plus-outline"></i></el-col>
					<el-col v-else :span="6" class="bigicon"><i @click="removeTime(timeOne)"
							class="el-icon-remove-outline"></i></el-col>
				</el-row>
			</el-row>

			<el-row>
				<el-col :span="20">
					<el-form-item label="报名起止时间" prop="jointime">
						<el-date-picker @change="jointimeChange" v-model="ruleForm.jointime" type="datetimerange"
							range-separator="至" value-format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始时间"
							end-placeholder="结束时间">
						</el-date-picker>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="12">
					<el-form-item label="活动允许参与人数" prop="joinnum">
						<el-input v-model="ruleForm.joinnum" oninput="value=value.replace(/[^\d]/g,'')"
							placeholder="请输入活动允许参数人数"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-form-item label="是否需要消费现金" prop="moneyflag" placeholder="请选择">
					<el-radio-group v-model="ruleForm.moneyflag">
						<el-radio label="1">是</el-radio>
						<el-radio label="0">否</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-col :span="12">
					<el-form-item label="消费金额" prop="moneynum" v-if="ruleForm.moneyflag == 1">
						<el-input v-model="ruleForm.moneynum"
							oninput="value=value.replace(/[^0-9.]/g,'').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')"
							placeholder="请输入消费金额">
							<template slot="append">元</template>
						</el-input>
					</el-form-item>
				</el-col>
			</el-row>

			<el-row>
				<el-form-item label="是否需要扣除积分" prop="pointflag" placeholder="请选择">
					<el-radio-group v-model="ruleForm.pointflag">
						<el-radio :label="1">是</el-radio>
						<el-radio :label="0">否</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-col :span="12">
					<el-form-item label="扣除积分数" prop="pointnums" v-if="ruleForm.pointflag == 1">
						<el-input v-model="ruleForm.pointnums" oninput="value=value.replace(/[^\d]/g,'')"
							placeholder="请输入扣除积分数"></el-input>
					</el-form-item>
				</el-col>
			</el-row>

			<el-row>
				<el-form-item label="扣除积分类型" prop="payment_method" placeholder="请选择" v-if="ruleForm.pointflag == 1">
					<el-checkbox-group v-model="ruleForm.payment_method">
						<el-checkbox v-for="pay in payTypes" :label="pay.value"
							:key="pay.id">{{ pay.label }}</el-checkbox>
					</el-checkbox-group>
				</el-form-item>
			</el-row>

			<el-row>
				<el-form-item label="活动结束后是否奖励积分" prop="addflag" placeholder="请选择">
					<el-radio-group v-model="ruleForm.addflag">
						<el-radio :label="1">是</el-radio>
						<el-radio :label="0">否</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-col :span="12">
					<el-form-item label="奖励积分数" prop="addpoint" v-if="ruleForm.addflag == 1">
						<el-input v-model="ruleForm.addpoint" oninput="value=value.replace(/[^\d]/g,'')"
							placeholder="请输入奖励积分数"></el-input>
					</el-form-item>
				</el-col>
			</el-row>

			<el-row v-show="false">
				<el-form-item label="是否开启短信提醒" prop="autosmsflag" placeholder="请选择">
					<el-radio-group v-model="ruleForm.autosmsflag">
						<el-radio label="1">是</el-radio>
						<el-radio label="0">否</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-col :span="12">
					<el-form-item label="短信发送时间" prop="smstime" v-if="ruleForm.autosmsflag == 1">
						<el-date-picker v-model="ruleForm.smstime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
							placeholder="请选择短信发送时间">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="短信发送内容" prop="smsmsg" v-if="ruleForm.autosmsflag == 1">
						<el-input v-model="ruleForm.smsmsg" type="textarea" placeholder="请输入短信发送时间"></el-input>
					</el-form-item>
				</el-col>
			</el-row>

			<el-row v-show="false">
				<el-form-item label="是否开启小程序消息" prop="minismsflag" placeholder="请选择">
					<el-radio-group v-model="ruleForm.minismsflag">
						<el-radio label="1">是</el-radio>
						<el-radio label="0">否</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-col :span="12">
					<el-form-item label="小程序消息发送时间" prop="minitime" v-if="ruleForm.minismsflag == 1">
						<el-date-picker v-model="ruleForm.minitime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
							placeholder="请选择小程序消息发送时间">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="小程序消息发送内容" prop="minimsg" v-if="ruleForm.minismsflag == 1">
						<el-input v-model="ruleForm.minimsg" type="textarea" placeholder="请输入短信发送时间"></el-input>
					</el-form-item>
				</el-col>
			</el-row>

			<el-row>
				<el-form-item label="用户报名后是否可取消" prop="deljoin" placeholder="请选择">
					<el-radio-group v-model="ruleForm.deljoin">
						<el-radio label="0">是</el-radio>
						<el-radio label="1">否</el-radio>
					</el-radio-group>
				</el-form-item>

				<el-form-item label="参与活动的用户类型" prop="joinflag" placeholder="请选择">
					<el-radio-group v-model="ruleForm.joinflag">
						<el-radio label="0">全部用户</el-radio>
						<el-radio label="1">实名用户</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-row>

			<el-row>
				<el-form-item label="是否允许重复参与" prop="allowrepeat" placeholder="请选择">
					<el-radio-group v-model="ruleForm.allowrepeat" @change="allowrepeatChange">
						<el-radio label="0">是</el-radio>
						<el-radio label="1">否</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-row>

			<el-row>
				<el-form-item label="是否允许一次报名多人" v-if="ruleForm.allowrepeat === '0'" prop="allowmultiple"
					placeholder="请选择">
					<el-radio-group v-model="ruleForm.allowmultiple">
						<el-radio label="0">是</el-radio>
						<el-radio label="1">否</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-col :span="12">
					<el-form-item label="一次报名人数上限" prop="applylimit" v-if="ruleForm.allowmultiple === '0'">
						<el-input v-model="ruleForm.applylimit"
							oninput="value=value.replace(/[^0-9.]/g,'').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')"
							placeholder="请输入一次报名人数上限"></el-input>
					</el-form-item>
				</el-col>
			</el-row>




			<el-row style="text-align: center;">
				<el-form-item label-width="0px">
					<el-button type="primary" :disabled="buttonDisabled"
						@click="submitForm('ruleForm')">创建项目信息</el-button>
					<el-button @click="resetForm('ruleForm')">重置</el-button>
				</el-form-item>
			</el-row>

		</el-form>
	</div>
</template>

<script>
	import {
		activityItemQuery,
		activityItemDel,
		activityItemUpdate,
		itemInfoAdd,
		activityItemArea,
		placeDroplist
	} from "@/api/acitvity/index.js";
	import {
		getDictItem
	} from '@/api/merchantManage'
	export default {
		name: "",
		components: {},
		props: {
			itemDetail: {
				type: Object,
				default: () => {
					return {}
				}
			}
		},
		data() {
			return {
				ruleForm: {
					activitytime: [],
					begintime: '',
					endtime: '',
					jointime: [],
					joinbegintime: '',
					joinendtime: '',
					days: '',
					times: [{
						value: ''
					}],
					joinnum: '',
					moneyflag: '',
					moneynum: '',
					pointflag: '',
					pointnums: '',
					addflag: '',
					addpoint: '',
					autosmsflag: '0',
					smstime: '',
					smsmsg: '',
					minismsflag: '0',
					minitime: '',
					minimsg: '',
					deljoin: '',
					joinflag: '',
					allowrepeat: '',
					allowmultiple: '',
					applylimit: '',
					zzareaid: '',
					zzareaname: '',
					payment_method: []
				},
				payTypes: [],
				zzareaOptions: [],
				buttonDisabled: false,
				rules: {
					activitytime: [{
						required: true,
						message: '请选择活动起止时间',
						trigger: 'change'
					}],
					jointime: [{
						required: true,
						message: '请选择报名起止时间',
						trigger: 'change'
					}],
					days: [{
						required: true,
						message: '请选择活动日期',
						trigger: 'change'
					}],
					times: [{
						required: true,
						message: '请选择活动时间',
						trigger: 'change'
					}],
					joinnum: [{
						required: true,
						message: '请输入活动允许参与人数',
						trigger: 'change'
					}],
					moneyflag: [{
						required: true,
						message: '请选择',
						trigger: 'change'
					}],
					moneynum: [{
						required: true,
						message: '请输入消费金额',
						trigger: 'change'
					}],
					pointflag: [{
						required: true,
						message: '请选择',
						trigger: 'change'
					}],
					pointnums: [{
						required: true,
						message: '请输入扣除积分数',
						trigger: 'change'
					}],
					addflag: [{
						required: true,
						message: '请选择',
						trigger: 'change'
					}],
					addpoint: [{
						required: true,
						message: '请输入奖励积分数',
						trigger: 'change'
					}],
					autosmsflag: [{
						required: true,
						message: '请选择',
						trigger: 'change'
					}],
					smstime: [{
						required: true,
						message: '请输选择短信发送时间',
						trigger: 'change'
					}],
					smsmsg: [{
						required: true,
						message: '请输入短信发送内容',
						trigger: 'change'
					}],
					minismsflag: [{
						required: true,
						message: '请选择',
						trigger: 'change'
					}],
					minitime: [{
						required: true,
						message: '请输入小程序消息发送时间',
						trigger: 'change'
					}],
					minimsg: [{
						required: true,
						message: '请输入小程序消息发送内容',
						trigger: 'change'
					}],
					deljoin: [{
						required: true,
						message: '请选择',
						trigger: 'change'
					}],
					joinflag: [{
						required: true,
						message: '请选择',
						trigger: 'change'
					}],
					allowrepeat: [{
						required: true,
						message: '请选择',
						trigger: 'change'
					}],
					allowmultiple: [{
						required: true,
						message: '请选择',
						trigger: 'change'
					}],
					applylimit: [{
						required: true,
						message: '请输入一次报名人数上限',
						trigger: 'change'
					}],
					zzarea: [{
						required: true,
						message: '请选择场地',
						trigger: 'change'
					}],
					payment_method: [{
						required: true,
						message: '请输入一次报名人数上限',
						trigger: 'change'
					}]
				}
			}
		},
		watch: {
			itemDetail(newval, oldval) {
				// console.log(newval)
			}
		},
		created() {},
		mounted() {
			this.getPayType();
		},
		computed: {},
		methods: {
			getPayType() {
				getDictItem({
					type: 'payment_method'
				}).then(res => {
					if (res.result == 200) {
						let result = res.detail;
						result.payment_method = result.payment_method ? result.payment_method.split(',') : [];
						this.payTypes = result;
					} else {
						this.$message.warning(res.description)
					}
				})
			},
			//获取场地列表
			getZzarea() {
				let levelCode = this.itemDetail.codes;
				placeDroplist({
					userid: this.GLOBAL.adminId(),
					levelcode: levelCode
				}).then(res => {
					if (res.result == 200) {
						// console.log(res)
						this.zzareaOptions = res.detail;
					} else {
						this.$message.warning(res.description)
					}
				})
			},
			//获取选择的场地名称
			getZzareaName(id) {
				if (id) {
					this.zzareaOptions.map(area => {
						if (area.id == id) {
							this.ruleForm.zzareaname = area.areaname;
						}
					})
				} else {
					this.ruleForm.zzareaname = ""
				}
			},

			//修改或新增项目提交
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let data = {
							userid: this.GLOBAL.adminId(),
							itemid: this.itemDetail.itemid,
							actuuid: this.itemDetail.actuuid,
							joinnum: this.ruleForm.joinnum,
							moneyflag: this.ruleForm.moneyflag,
							moneynum: this.ruleForm.moneyflag == 1?this.ruleForm.moneynum:'',
							pointflag: this.ruleForm.pointflag,
							pointnums: this.ruleForm.pointflag == 1?this.ruleForm.pointnums:'',
							addflag: this.ruleForm.addflag,
							addpoint: this.ruleForm.addflag == 1?this.ruleForm.addpoint:'',
							autosmsflag: this.ruleForm.autosmsflag,
							smstime: this.ruleForm.smstime,
							smsmsg: this.ruleForm.smsmsg,
							minismsflag: this.ruleForm.minismsflag,
							minitime: this.ruleForm.minitime,
							minimsg: this.ruleForm.minimsg,
							deljoin: this.ruleForm.deljoin,
							joinflag: this.ruleForm.joinflag,
							allowrepeat: this.ruleForm.allowrepeat,
							allowmultiple: this.ruleForm.allowmultiple,
							applylimit: this.ruleForm.applylimit,
							zzareaid: this.ruleForm.zzareaid,
							zzareaname: this.ruleForm.zzareaname,
							joinbegintime: this.ruleForm.joinbegintime,
							joinendtime: this.ruleForm.joinendtime,
							paymentMethod: this.ruleForm.pointflag == 1?this.ruleForm.payment_method.join(','):''
						}
						if (this.itemDetail.byday == 0) {
							data.begintime = this.ruleForm.begintime
							data.endtime = this.ruleForm.endtime
						}
						if (this.itemDetail.byday == 1) {
							data.days = this.ruleForm.days;
							let times = this.ruleForm.times
							let timesArr = [];
							times.map(item => {
								timesArr.push(item.value);
							})
							data.times = timesArr.join(',');
						}
						this.buttonDisabled = true;
						itemInfoAdd(data).then(res => {
							this.buttonDisabled = false;
							if (res.result == 200) {
								this.$message.success('项目信息创建成功');
								this.resetForm('ruleForm');
								this.$emit('refreshInfosList')
							} else {
								this.$message.warning(res.description)
							}
						}).catch(err => {
							this.buttonDisabled = false;
						})

					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			//重置表单
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			addTime() {
				this.ruleForm.times.push({
					value: '',
					key: Date.now()
				});
			},
			removeTime(item) {
				var index = this.ruleForm.times.indexOf(item)
				if (index !== -1) {
					this.ruleForm.times.splice(index, 1)
				}
			},
			//设置起止时间
			activitytimeChange(data) {
				// console.log(data)
				if (data) {
					this.ruleForm.begintime = data[0];
					this.ruleForm.endtime = data[1]
				}
			},
			jointimeChange(data) {
				if (data) {
					this.ruleForm.joinbegintime = data[0];
					this.ruleForm.joinendtime = data[1]
				} else {
					this.ruleForm.joinbegintime = '';
					this.ruleForm.joinendtime = '';
					this.ruleForm.jointime = [];
				}
			},
			//是否允许重复参与
			allowrepeatChange(data) {
				if (data == '1') {
					this.ruleForm.allowmultiple = '1';
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.bigicon {
		font-size: 24px;
		color: #999;
		opacity: 0.6;

		.el-icon-remove-outline {
			color: #F56C6C;
		}

		.el-icon-circle-plus-outline {
			color: #67C23A;
		}

		i {
			cursor: pointer;
		}
	}
</style>