<template>
  <div class="wrapper">
    <div class="searchbox" v-if="listType === 'check'">
      <el-form :inline="true">
        <el-form-item label="活动名称">
          <el-input v-model="search.actname" clearable placeholder="请选择"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="searchbox" v-else>
      <el-form :inline="true">
        <el-form-item label="活动名称">
          <el-input v-model="search.actname" clearable placeholder="请选择"></el-input>
        </el-form-item>

        <el-form-item label="是否置顶">
          <el-select v-model="search.stickflag" clearable placeholder="请选择">
            <el-option label="是" value="1"></el-option>
            <el-option label="否" value="0"></el-option>
          </el-select>
        </el-form-item>

        

        <el-form-item label="只看当前选中社区">
          <el-select v-model="search.justOnwer" clearable placeholder="请选择">
            <el-option label="是" value="1"></el-option>
            <el-option label="否" value="0"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="选择社区">
          <el-select v-model="search.searchCommunityCode" clearable collapse-tags multiple placeholder="请选择">
            <el-option
              v-for="item in communitys"
              :key="item.levelcode"
              :label="item.name"
              :value="item.levelcode">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="活动类型">
          <el-select v-model="search.acttype" clearable placeholder="请选择活动类型">
            <el-option v-for="item in activityTypes" :label="item.label" :value="item.value" :key="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="上下架状态">
          <el-select v-model="search.activityflag" clearable placeholder="请选择上下架状态">
            <el-option v-for="item in upDownStatus" :label="item.label"  :value="item.value" :key="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="审核状态">
          <el-select v-model="search.checkflag" clearable placeholder="请选择审核状态">
            <el-option v-for="item in checkStatus" :label="item.label"  :value="item.value" :key="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="活动分类" prop="category">
        	<el-select v-model="search.category" placeholder="请选择活动分类" clearable>
        		<el-option v-for="item in categoryTypes" :label="item.name" :value="item.uuid"
        			:key="item.uuid"></el-option>
        	</el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button type="primary" @click="addActivityFn">新增活动</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="showdatanumber">共查询数据<span>{{ fenye.totalnum }}</span>条</div>
    <el-table :data="tableData" border stripe style="width: 100%">
      <el-table-column prop="actname" label="活动名称"  show-overflow-tooltip  width="180px"  align="center">
      </el-table-column>
      <el-table-column prop="acttype" label="活动类型" align="center" :formatter="activityTypesFormatter">
      </el-table-column>
	  <el-table-column prop="categoryname" label="活动分类" align="center" >
	  </el-table-column>
      <el-table-column prop="checkflag" label="活动审核状态" width="100px" align="center" :formatter="checkStatusFormatter">
      </el-table-column>
      <el-table-column label="审核意见" width="120px" show-overflow-tooltip align="center" :formatter="checkStatusFormatter">
        <template slot-scope="scope">
          <span v-if="scope.row.checkflag == 3">{{ scope.row.checkmsg }}</span>
          <span v-else>/</span>
        </template>
      </el-table-column>
      <el-table-column prop="activityflag" label="上下架状态"  width="100px"  align="center" :formatter="upDownStatusFormatter">
      </el-table-column>
      <el-table-column prop="activityflag" label="活动二维码"  width="100px"  align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.activityflag == 1" style="cursor: pointer;"><img @click="openQrcode(scope.row)" style="width: 20px; height: 20px;" src="../../../assets/imgs/qrcode.png" alt=""></span>
          <span v-else>/</span>
        </template>
      </el-table-column>
      <el-table-column prop="joincount" label="参与数" align="center">
      </el-table-column>
      <el-table-column prop="todayjoin" label="今日报名" align="center">
        <template slot-scope="scope">
          <span :style=" { color: scope.row.todayjoin > 0 ? 'red' : 'unset' } ">{{scope.row.todayjoin}}</span>
        </template>
      </el-table-column>
	  <el-table-column label="党员参与人数" width="70" align="center" prop="dyjoincount">
	  </el-table-column>
	  <el-table-column label="特殊困难群体参与人数" width="70" align="center" prop="knqtjoincount">
	  </el-table-column>
      <el-table-column prop="actcommuname" label="社区" show-overflow-tooltip  width="140px" align="center">
      </el-table-column>
      <el-table-column prop="begintime" label="活动开始时间" width="150px" align="center">
      </el-table-column>
      <el-table-column prop="endtime" label="活动结束时间" width="150px" align="center">
      </el-table-column>
      <el-table-column prop="createmanname" label="创建人"  width="120px" align="center">
      </el-table-column>
      <el-table-column prop="createtime" label="创建时间" width="150px" align="center">
      </el-table-column>

      <!--
        1、管理员可以做一切操作
        2、登录账号的Levelcode和创建志愿服务的Levelcode相同时（scope.row.levelcode === userLevelcode ）可做一切操作，否则只能查看
      -->

      <el-table-column label="操作" fixed="right" width="280" align="center">
        <template slot-scope="scope">
          <el-row v-if="listType === 'check' || (scope.row.levelcode !== userLevelcode && userLevelcode !== '-1')">
            <el-button @click="activityDetail(scope.row)" type="primary">查看活动</el-button>
            <el-button @click="openItemsListFn(scope.row)" v-if="scope.row.acttype != 2" type="primary">查看项目</el-button>
            <el-button @click="checkActivity(scope.row)" v-if="listType === 'check'" type="success">审核</el-button>
          </el-row>

          <el-row  v-else>
            <el-button @click="editActivityMain(scope.row)" type="primary">修改活动</el-button>
            <el-button :disabled="scope.row.activityflag == 1" @click="deleteActivityFn(scope.row)" type="danger">删除</el-button>

            <el-popover
              placement="left"
              style="margin-left: 10px;"
              trigger="click">
              <div class="btnsbox">
                  <el-button @click="keyItems(scope.row)" v-if="scope.row.acttype != 2" type="primary">活动报名项</el-button>
                  <el-button @click="openItemsListFn(scope.row)" v-if="scope.row.acttype != 2" type="primary">项目</el-button>
                  <el-button @click="joinedList(scope.row)" v-if="scope.row.acttype != 2" type="success" plain>参与列表</el-button>
                  <el-button v-if="scope.row.checkflag == 0 || scope.row.checkflag == 3" @click="checkSubmit(scope.row)" type="primary">提交审核</el-button>
                <!-- 当审核状态为已审核通过时checkflag=2，才有上架、下架按钮 -->
                  <el-button @click="upOrDown(scope.row)" v-if="scope.row.checkflag == 2 && scope.row.activityflag == 2" type="success">上架</el-button>
                  <el-button @click="upOrDown(scope.row)" v-if="scope.row.checkflag == 2 && scope.row.activityflag == 1" type="warning">下架</el-button>
                  <el-button @click="stickOrNo(scope.row)" v-if="scope.row.stickflag == 1" type="warning">取消置顶</el-button>
                  <el-button @click="stickOrNo(scope.row)" v-if="scope.row.stickflag == 0" type="success">置顶</el-button>
              </div>
              <el-button slot="reference">更多</el-button>
            </el-popover>
          </el-row>

          
        </template>
      </el-table-column>

    </el-table>

    <div class="paginationbox">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="fenye.pagesizes" :page-size="fenye.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="fenye.totalnum">
			</el-pagination>
		</div>

    
    <!-- 项目列表展示 -->
    <el-dialog title="项目列表" :visible.sync="dialogVisible" width="70%">
      <item-list :actuuid="currentActuuid" :listType="(listType=='check' || (currentActLevel !== userLevelcode && userLevelcode !== '-1'))?'check':''" :activityFlag="activityFlag"></item-list>
    </el-dialog>


    <!-- 修改活动主体 -->
    <el-dialog append-to-body title="修改活动" :close-on-click-modal='false' :visible.sync="updateDialogVisible"  width="70%" :before-close="updatehandleClose">
      <edit-activity :updateActuuid="updateActuuid" :activityEditType="activityEditType" :activityFlag="activityFlag" @updateRefreshActsList="updateRefreshActsList" ref="act"></edit-activity>
    </el-dialog>

    <!-- 查看活动主体 -->
    <el-dialog append-to-body title="查看活动" :close-on-click-modal='false' :visible.sync="actDetailDialogVisible"  width="70%">
      <edit-activity :updateActuuid="updateActuuid" :activityEditType="activityEditType" ref="actDetail"></edit-activity>
    </el-dialog>

    <!-- 活动审核 -->
    <el-dialog append-to-body title="审核" :close-on-click-modal='false' :visible.sync="checkDialogVisible"  width="500px" :before-close="checkhandleClose">
      <el-form :model="form" :rules="rules" ref="form" label-width="100px">
        <el-form-item label="审核结果" prop="ispass">
          <el-radio-group v-model="form.ispass">
            <el-radio label="1">通过</el-radio>
            <el-radio label="0">不通过</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="审核意见" prop="opinion" v-if="form.ispass === '0'">
          <el-input type="textarea" v-model="form.opinion"></el-input>
        </el-form-item>
        <el-form-item label-width="10px" style="margin-top: 40px; text-align: center;">
          <el-button type="primary" @click="submitForm('form')">提交审核</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>


    <!-- 活动字段项 -->
    <el-dialog append-to-body title="报名字段项" :close-on-click-modal='false' :visible.sync="keyItemsDialogVisible"  width="780px" :before-close="keyItemsHandleClose">
        <el-form :model="keyItemsForm" class="keyitemsbox" ref="keyItemsForm" label-width="100px">
          <el-form-item
            v-for="(keyItem, index) in keyItemsForm.keyItems"
            :label="'字段名 ' + (index+1)"
            :key="keyItem.key"
            :prop="'keyItems.' + index + '.value'"
            :rules="{
              required: true, message: '请输入字段名', trigger: 'blur'
            }"
          >
            <el-input v-model="keyItem.value"></el-input>

            <el-radio-group v-model="keyItem.require" style="margin: 0 10px;">
              <el-radio-button label="0">非必填</el-radio-button>
              <el-radio-button label="1">必填</el-radio-button>
            </el-radio-group>
            <el-button class="btn" @click.prevent="removeKeyItem(keyItem)" :disabled="activityFlag=== '1'">删除</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="keyItemsFormSubmit('keyItemsForm')" :disabled="activityFlag=== '1'">提交</el-button>
            <el-button @click="addKeyItem" :disabled="activityFlag=== '1'">新增字段</el-button>
          </el-form-item>
        </el-form>
    </el-dialog>


    <!-- 参与列表 -->
    <el-dialog append-to-body title="参与列表" v-if="joinListDialogVisible" :close-on-click-modal='false' :visible.sync="joinListDialogVisible"  width="70%">
      <join-list ref="joinList" :actuuid="joinActuuid"></join-list>
    </el-dialog>

    <!-- 二维码 -->
    <el-dialog
      :title="qrcodeTitle"
      :visible.sync="qrcodedialogVisible"
      width="400px">
      <div style="text-align: center;">
        <el-image 
          style="width: 300px; height: 300px;"
          :src="qrcodeUrl">
        </el-image>
      </div>
    </el-dialog>


  </div>
</template>

<script>
import dicts from '@/utils/dictionary'
import editActivity from '../addActivity';
import itemList from './items'
import joinList from '../activityJoinlist'
import { communityOfUser } from '@/api/system';
import { activityQueryPage, checkBeforeDelete, activityDel, upDownActivity, submitCheck, stickActivity, auditAct, addActKey, getActKey,activityKind, qrcodeProduct } from '@/api/acitvity/index.js'
export default {
  name:"",
  components:{
    itemList,
    editActivity,
    joinList
  },
  props:{
    listType: {
      type: String,
      default: ''
    }
  },
  data(){
    return {
      userLevelcode: localStorage.getItem('level'),
      activityTypes: dicts.activityType,
      checkStatus: dicts.checkStatus,
      upDownStatus: dicts.upDownStatus,
      tableData:[],
      currentPage: 1,
      fenye: {
        totalnum: 0,
        pagesizes: [10, 20, 30, 40, 50],
        pagesize: 10
      },
      operationType: 'add',
      communitys: [],
      search: {
        actname:'',
        justOnwer: '',
        searchCommunityCode: '',
        acttype: '',
        stickflag: '',
        activityflag:'',
        checkflag: '',
		    category: ''
      },
      dialogVisible: false,
      currentActuuid:'',
      currentActLevel: '',
      //修改活动主体字段
      activityEditType: 'add',
      updateActuuid: '',
      updateDialogVisible: false,
      //审核活动字段
      checkDialogVisible: false,
      form: {
        ispass: '',
        opinion: ''
      },
      checkActuuid:'',
      rules:{
        ispass: [
          { required: true, message: '请选择审核结果', trigger: 'change' }
        ],
        opinion: [
          { required: true, message: '请输入审核意见', trigger: 'change' }
        ]
      },
      //查看活动主体字段
      actDetailDialogVisible: false,
      //报名字段项字段
      keyItemsDialogVisible: false,
      keyItemsForm: {
        keyItems: [{
          value: '',
          key: '',
          require: '1'
        }]
      },
      keyItemsOfActuuid: '',
      //活动上架状态
      activityFlag: "",
      //参与列表字段
      joinListDialogVisible: false,
      joinActuuid: '',
	    categoryTypes: [],
      qrcodeUrl:'',
      qrcodedialogVisible: false,
      qrcodeTitle: ''

    }
  },
  watch:{},
  activated() {
    this.getList()
  },
  created(){},
  mounted(){
    this.getCommunityOfUser();
	this.getActivityKinds();
  },
  computed:{},
  methods:{
    //获取当前登录用户的管辖社区
    getCommunityOfUser(){
      communityOfUser({
        userid: this.GLOBAL.adminId()
      }).then(res=>{
        if(res.result == 200){
          this.communitys =  res.detail;
          this.getList();
        } else {
          this.$message.error(res.description);
        }
      })
    },
    //获取列表
    getList() {
      let arealevelArr = []
      if(this.search.searchCommunityCode.length == 0){
        this.communitys.map(item=>{
          arealevelArr.push(item.levelcode)
        })
      }else{
        arealevelArr = [...this.search.searchCommunityCode];
      }
      activityQueryPage({
          userid: this.GLOBAL.adminId(),
          actname: this.search.actname,
          justOnwer: this.search.justOnwer,
          searchCommunityCode: arealevelArr.join(','),
          acttype: this.search.acttype,
          stickflag: this.search.stickflag,
          activityflag: this.search.activityflag,
          //如果是活动审核，则只筛选'待审核1'的数据
          checkflag: this.listType === 'check' ? '1' :this.search.checkflag,
          pageSize: this.fenye.pagesize,
          pageNum: this.currentPage,
		  category: this.search.category
      }).then(res=>{
        if(res.result == 200){
          this.fenye.totalnum = res.detail.totalRow;
          this.tableData = res.detail.list;
        } else {
          this.$message.error(res.description);
        }
      })
    },
    //搜索
    onSearch(){
      this.currentPage = 1;
      this.getList();
    },
    //新增活动
    addActivityFn(){
      this.$router.push({
        path: '/activity/chooseactivity'
      })
    },
    //获取活动二维码
    openQrcode(row){
      this.qrcodeUrl = "";
      this.qrcodeTitle = row.actname + ' 活动二维码';
      this.qrcodedialogVisible = true;
      qrcodeProduct({
        uuid: row.actuuid
      }).then(res=>{
        if(res.result == 200){
          this.qrcodeUrl = res.detail;
        } else {
          this.$message.error(res.description);
        }
      })
    },
    
    //删除活动主体前的校验
    deleteActivityFn(row){
      this.$confirm('是否确认删除该活动?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          checkBeforeDelete({
            userid: this.GLOBAL.adminId(),
            actuuid: row.actuuid
          }).then(res=>{
            if(res.result == 200){
              let joincount = res.detail.joincount;
              if(joincount == 0){
                this.deleteActivity(row.actuuid)
              }else{
                this.$confirm(`该活动已经有${joincount}人参加，是否确认删除?`, '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(() => {
                  this.deleteActivity(row.actuuid)
                }).catch(() => {
                });
              }
            } else {
              this.$message.error(res.description);
            }
          })
        }).catch(() => {
                 
        });
    },
    //删除活动
    deleteActivity(actuuid){
      activityDel({
        userid: this.GLOBAL.adminId(),
        actuuid: actuuid
      }).then(res=>{
        if(res.result == 200){
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.onSearch();
        } else {
          this.$message.error(res.description);
        }
      })
    },
    //切换页面条数
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.fenye.pagesize = val;
      this.currentPage = 1;
      this.getList();
    },
    //切换分页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    //提交审核
    checkSubmit(row){
      // console.log('提交审核',row)
      this.$confirm('是否确认提交审核?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          submitCheck({
            userid: this.GLOBAL.adminId(),
            actuuid: row.actuuid
          }).then(res=>{
            if(res.result == 200){
              this.$message.success('已提交审核，请等待审核');
              this.getList();
            } else {
              this.$message.error(res.description);
            }
          })
        }).catch(() => {
                 
        });
      
    },
    //上架/下架
    upOrDown(row){
      // console.log('上架/下架')

      let activityflag = row.activityflag;
      let newActivityflag = null;
      let tipMsg = null;
      if(activityflag == 1){  //上架状态
        newActivityflag = 2;
        tipMsg = "是否确认下架？"
      }else if(activityflag == 2){  //下架状态
        newActivityflag = 1
        tipMsg = "是否确认上架？"
      }

      this.$confirm(`${tipMsg}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        
        upDownActivity({
          userid: this.GLOBAL.adminId(),
          actuuid: row.actuuid,
          activityflag: newActivityflag
        }).then(res=>{
          if(res.result == 200){
            this.$message.success(res.detail);
            this.getList();
          } else {
            this.$message.error(res.description);
          }
        })

      }).catch(() => {
                
      });
      

    },
    //置顶/取消置顶
    stickOrNo(row){
      // console.log('置顶/取消置顶')
      if(row.activityflag == 1){
        this.$message.warning('该活动处理上架状态，请先下架，再进行置顶/取消置顶操作');
        return false;
      }
      if(row.checkflag == 2){
        this.$confirm('该活动已审核通过，如进行该操作则需重新审核，是否确认操作?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.getStickApi(row);
        }).catch(() => {
                 
        });
      }else{
        this.getStickApi(row);
      }
      
      
    },
    //调置顶/取消置顶接口
    getStickApi(row){
      let stickflag = row.stickflag;
      let newStickflag = null;
      if(stickflag == 1){  //置顶状态
        newStickflag = 0
      }else if(stickflag == 0){  //置顶状态
        newStickflag = 1
      }
      stickActivity({
        userid: this.GLOBAL.adminId(),
        actuuid: row.actuuid,
        stickflag: newStickflag
      }).then(res=>{
        if(res.result == 200){
          this.$message.success(res.detail + '如需上架，请提交审核');
          this.getList();
        } else {
          this.$message.error(res.description);
        }
      })
    },
    //活动类型
    activityTypesFormatter(row, column) {
      let type = null
      this.activityTypes.map(item=>{
        if(row.acttype == item.value){
          type =  item.label;
        }
      })
      return type
    },
    //审核状态
    checkStatusFormatter(row, column) {
      let status = null
      this.checkStatus.map(item=>{
        if(row.checkflag == item.value){
          status = item.label;
        }
      })
      return status
    },
    //上架或下架状态
    upDownStatusFormatter(row, column) {
      let status = null
      this.upDownStatus.map(item=>{
        if(row.activityflag == item.value){
          status =  item.label;
        }
      })
      return status
    },
    //打开活动项目列表
    openItemsListFn(row){
      this.currentActuuid = row.actuuid;
      this.currentActLevel = row.levelcode;
      this.activityFlag = row.activityflag;
      this.dialogVisible = true;
    },
    //打开修改活动主体弹框、获取主体信息
    editActivityMain(row){
      this.activityEditType = 'update';
      this.updateActuuid = row.actuuid;
      this.activityFlag = row.activityflag;
      this.updateDialogVisible = true;
      this.$nextTick(()=>{
        this.$refs.act.getActDetail();
      })
    },
    //刷新活动主体列表
    updateRefreshActsList(){
      this.currentPage = 1;
      this.getList();
      this.updateDialogVisible = false;
    },
    //重置表单
    updatehandleClose(done) {
      this.$refs.act.resetForm('ruleForm');
      this.$refs.act.fileList = [];
      this.$refs.act.fileListUnder = [];
      done();
    },

    //查看活动
    activityDetail(row){
      this.activityEditType = 'detail';
      this.updateActuuid = row.actuuid;
      this.actDetailDialogVisible = true;
      this.$nextTick(()=>{
        this.$refs.actDetail.getActDetail();
      })
    },
    




    //审核活动，打开弹框、获取主体信息
    checkActivity(row){
      this.checkDialogVisible = true;
      this.checkActuuid = row.actuuid;
    },
    //关闭审核表单
    checkhandleClose(done) {
      this.resetForm('form')
      done();
    },
    //审核按钮
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm('是否确认提交审核?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            auditAct({
              userid: this.GLOBAL.adminId(),
              actuuid: this.checkActuuid,
              ispass: this.form.ispass,
              opinion: this.form.opinion
            }).then(res=>{
              if(res.result == 200){
                this.$message.success('操作成功');
                this.resetForm('form');
                this.checkDialogVisible = false;
                this.checkActuuid = '';
                this.getList();
              } else {
                this.$message.error(res.description);
              }
            })
          }).catch(() => {
                  
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //审核表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //添加活动报名项
    keyItems(row){
      // console.log(row)
      this.keyItemsDialogVisible = true;
      this.keyItemsOfActuuid = row.actuuid;
      this.activityFlag = row.activityflag;
      getActKey({
        userid: this.GLOBAL.adminId(),
        actuuid: this.keyItemsOfActuuid,
      }).then(res=>{
        if(res.result == 200){
            // console.log(res)
            let detail = res.detail;
            if(detail.length > 0){
              this.keyItemsForm.keyItems = [];
              detail.map(item=>{
                this.keyItemsForm.keyItems.push({
                  value: item.keyname,
                  key: item.id,
                  require: item.required
                })
              })
            }
        } else {
          this.$message.error(res.description);
        }
      })

    },
    //提交字段
    keyItemsFormSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let requireFlag = true;
          this.keyItemsForm.keyItems.forEach(item=>{
            if(item.require === ''){
              requireFlag = false
            }
          })
          if(!requireFlag){
            this.$message.warning('请选择是否为必填项');
            return false;
          }
          this.$confirm('是否确认提交?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            let keynamesArr = this.keyItemsForm.keyItems;
            let keynames = [];
            keynamesArr.map(item=>{
              keynames.push(item.value+':'+item.require)
            })
            addActKey({
              userid: this.GLOBAL.adminId(),
              actuuid: this.keyItemsOfActuuid,
              keynames: keynames.length>0 ? keynames.join(',') : ''
            }).then(res=>{
              if(res.result == 200){
                  this.$message.success('操作成功');
                  this.keyItemsDialogVisible = false;
                  this.$refs[formName].resetFields();
              } else {
                this.$message.error(res.description);
              }
            })
          }).catch(() => {
                  
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //删除字段
    removeKeyItem(item) {
      var index = this.keyItemsForm.keyItems.indexOf(item)
      if (index !== -1) {
        this.keyItemsForm.keyItems.splice(index, 1)
      }
    },
    //新增字段
    addKeyItem() {
      this.keyItemsForm.keyItems.push({
        value: '',
        key: Date.now(),
        require: ''
      });
    },
    //关闭报名字段项弹框
    keyItemsHandleClose(done){
      this.$refs.keyItemsForm.resetFields();
      done();
    },
    //参与列表
    joinedList(row){
      console.log('参与列表')
      this.joinListDialogVisible = true;
      this.$nextTick(()=>{
        this.$refs.joinList.currentPage = 1;
      })
      this.joinActuuid = row.actuuid;
    },
	//获取活动种类
	getActivityKinds() {
		activityKind({
			userid: this.GLOBAL.adminId()
		}).then(res => {
			if (res.result == 200) {
				this.categoryTypes = res.detail;
			} else {
				this.$message.error(res.description);
			}
		})
	}
    
		  
  }
}
</script>

<style lang="scss" scoped>
  .paginationbox{
    text-align: right;
    margin-top: 20px;
  }
  .showdatanumber{
    margin: 0 10px 10px;
    color: #409EFF;
    span{
      margin: 0 5px;
      font-size: 20px;
    }
  }
  .searchbox{
    margin: 0 10px;
  }
  .formbox{
    width: 94%;
  }

  .keyitemsbox{ 
    .el-input { 
      width: 350px;
    }
    .btn{
      margin-left: 10px;
    }
  }
  .btnsbox{
    >div{
      margin: 5px 0;
    }
  }
</style>