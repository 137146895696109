import request from '@/utils/request'

//-------------活动---------
//新增或修改活动主体
export function activityMainApi(data) {
  return request({
    url: '/MA_actActivity/activityAddOrUpdate',
    method: 'post',
    data: data
  })
}

//新增或者修改活动区域
export function activityAreaApi(data) {
  return request({
    url: '/MA_actActivityArea/addActivityArea',
    method: 'post',
    params: data
  })
}

//活动区域查询
export function activityAreaDetail(data) {
  return request({
    url: '/MA_actActivityArea/activityAreaDetail',
    method: 'post',
    params: data
  })
}


//活动主体分页查询
export function activityQueryPage(data) {
  return request({
    url: '/MA_actActivity/activityQueryPage',
    method: 'post',
    params: data
  })
}

//活动主体数据删除前检验
export function checkBeforeDelete(data) {
  return request({
    url: '/MA_actActivity/checkBeforeDelete',
    method: 'post',
    params: data
  })
}
//活动主体信息查询
export function activityDetail(data) {
  return request({
    url: '/MA_actActivity/detail',
    method: 'post',
    params: data
  })
}

//活动主体数据删除
export function activityDel(data) {
  return request({
    url: '/MA_actActivity/activityDel',
    method: 'post',
    params: data
  })
}

//活动提交审核
export function submitCheck(data) {
  return request({
    url: '/MA_actActivity/submit',
    method: 'post',
    params: data
  })
}

//活动审核
export function auditAct(data) {
  return request({
    url: '/MA_actActivity/audit',
    method: 'post',
    params: data
  })
}



//活动上架OR下架
export function upDownActivity(data) {
  return request({
    url: '/MA_actActivity/upDownActivity',
    method: 'post',
    params: data
  })
}

//活动置顶或者取消
export function stickActivity(data) {
  return request({
    url: '/MA_actActivity/stickActivity',
    method: 'post',
    params: data
  })
}


//活动种类
export function activityKind(data) {
  return request({
    url: '/MA_actActivity/actCategory',
    method: 'post',
    params: data
  })
}






//分页查询项目
export function activityItemQuery(data) {
  return request({
    url: '/MA_actActivityItem/itemQueryPage',
    method: 'post',
    params: data
  })
}

//删除项目
export function activityItemDel(data) {
  return request({
    url: '/MA_actActivityItem/delActItem',
    method: 'post',
    params: data
  })
}

//修改项目
export function activityItemUpdate(data) {
  return request({
    url: '/MA_actActivityItem/updateActivityItem',
    method: 'post',
    data: data
  })
}

//新增项目
export function activityItemAdd(data) {
  return request({
    url: '/MA_actActivityItem/addActivityItem',
    method: 'post',
    data: data
  })
}

//查询项目详情
export function itemDetail(data) {
  return request({
    url: '/MA_actActivityItem/itemDetail',
    method: 'post',
    params: data
  })
}


//新增或者修改活动项目区域
export function activityItemArea(data) {
  return request({
    url: '/MA_actActivityItemArea/addActivityArea',
    method: 'post',
    params: data
  })
}



//项目信息列表
export function itemInfoListQuery(data) {
  return request({
    url: '/MA_actActivityItemInfo/activityItemInfoPage',
    method: 'post',
    params: data
  })
}

//新增项目信息接口
export function itemInfoAdd(data) {
  return request({
    url: '/MA_actActivityItemInfo/addActivityItem',    
    method: 'post',
    params: data
  })
}

//项目信息详情查询接口
export function itemInfoDetail(data) {
  return request({
    url: '/MA_actActivityItemInfo/findItemInfoDetail',
    method: 'post',
    params: data
  })
}

//项目信息修改接口
export function itemInfoUpdate(data) {
  return request({
    url: '/MA_actActivityItemInfo/update',
    method: 'post',
    params: data
  })
}

//删除项目信息
export function itemInfoDelete(data) {
  return request({
    url: '/MA_actActivityItemInfo/delete',
    method: 'post',
    params: data
  })
}

//活动key查询
export function getActKey(data) {
  return request({
    url: '/MA_actActivitykey/getActKey',
    method: 'post',
    params: data
  })
}

//新增活动KEY信息
export function addActKey(data) {
  return request({
    url: '/MA_actActivitykey/addActKey',
    method: 'post',
    data: data
  })
}


//项目信息参与接口
export function listQuery(data) {
  return request({
    url: '/MA_actActivityItemInfo/activityjoinPage',
    method: 'post',
    params: data
  })
}


//活动参与人员列表接口
export function activityjoinPage(data) {
  return request({
    url: '/MA_actActivity/activityjoinPage',
    method: 'post',
    params: data
  })
}



//场地列表
export function placeDroplist(data) {
  return request({
    url: '/MA_LevelArea/placeDroplist',
    method: 'post',
    params: data
  })
}

//获取场地列表
export function placeList(data) {
  return request({
    url: '/MA_LevelArea/placeList',
    method: 'post',
    params: data
  })
}

//新增场地
export function addPlace(data) {
  return request({
    url: '/MA_LevelArea/addPlace',
    method: 'post',
    params: data
  })
}

//修改场地
export function updatePlace(data) {
  return request({
    url: '/MA_LevelArea/updatePlace',
    method: 'post',
    params: data
  })
}

//删除场地
export function deletePlace(data) {
  return request({
    url: '/MA_LevelArea/deletePlace',
    method: 'post',
    params: data
  })
}

//门禁列表
export function doorListAboutPlace(data) {
  return request({
    url: '/MA_LevelArea/doorList',
    method: 'post',
    params: data
  })
}







//活动报名审核门禁
export function doorAuditList(data) {
  return request({
    url: '/MA_actActivity/joinAuditPage',
    method: 'post',
    params: data
  })
}

//场地活动门禁审核
export function doorAudit(data) {
  return request({
    url: '/MA_actActivity/dooraudit',
    method: 'post',
    params: data
  })
}


//关联门禁的活动
export function getActivityListForDooraudit(data) {
  return request({
    url: '/MA_actActivity/getActivityListForDooraudit',
    method: 'post',
    params: data
  })
}

// 新增场地
export function addOrUpdateInfo(data) {
  return request({
    url: '/MA_site/addOrUpdateInfo',
    method: 'post',
    data: data
  })
}

// 场地列表
export function querySitePage(data) {
  return request({
    url: '/MA_site/queryPage',
    method: 'post',
    params: data
  })
}

// 场地删除
export function delSite(data) {
  return request({
    url: '/MA_site/activityDel',
    method: 'post',
    params: data
  })
}
// 场地详情
export function siteDetail(data) {
  return request({
    url: '/MA_site/detail',
    method: 'post',
    params: data
  })
}

//取消报名
export function joincancel(data) {
  return request({
    url: '/MA_actActivity/joincancel',
    method: 'post',
    params: data
  })
}


//生成活动二维码
export function qrcodeProduct(data) {
  return request({
    url: '/MA_volActivity/createActCode',
    method: 'post',
    params: data
  })
}